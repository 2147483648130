$white: $white;
$pagination-primary-color: $bc2;
$pagination-current-color: $bc1;
$pagination-radius: 4px;


body .pagination{
    overflow: visible;
    background: transparent;
    @include breakpoint(medium up){
      float: right;
    }
    
    li{
        display: inline-block;
        margin-right: 0.425rem;

        a{
          padding: 0.58rem 1.05rem;
        //  height: 40px;
        //  width: 46px;
          border-radius: 3px;
          background-color: #E0DED8;
          color: $bc4;         ;
         
        }
    }

    .current{
      background: linear-gradient(180deg, $bc2 0%, $bc1 100%) !important;
      height: 40px;
      width: 46px;
      display: inline-block;
      line-height: 40px;
      padding: 0;
    }
   
}

.pagination-pointed {   

  .pagination-pointed-button-a,
  .pagination-pointed-button a{
    display: none;
    @include breakpoint(medium up) {
        display: inline-block;
    }
    position: relative;
    padding: 10px 15px;
    margin: 0 0.1rem;
    margin-bottom: 0;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    border-radius: $pagination-radius;
    background: linear-gradient(180deg, $bc2 0%, $bc1 100%) !important;
    color: $white;
    outline: 0;
    text-decoration: none;
    transition: all 0.2s linear;

    &:hover {
      background-color: lighten($pagination-primary-color, 10%);
    }
  }


    .pagination-pointed-button{
    .page-numbers.dots{
      @extend .pagination-pointed-button-a;
      background-color: lighten($pagination-primary-color, 50%);
      display: none;
      @include breakpoint(medium up) {
          display: inline-block;
      }
    }
  }


  span.current {
    @extend .pagination-pointed-button-a;
    display: inline-block;
    background: linear-gradient(180deg, $bc2 0%, $bc1 100%) !important;

    &:hover {
      background-color: darken($pagination-current-color, 10%);
    }
  }

  .next{
    display: inline-block !important;
    border-radius: $pagination-radius 0 0 $pagination-radius;
    margin-right: 18px;

    &:hover::after {
      border-left: 17px solid lighten($pagination-primary-color, 10%);
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: -17px;
      width: 0;
      height: 0;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
      border-left: 17px solid $pagination-primary-color;
      transition: all 0.2s linear;
    }
  }

  .prev {
    display: inline-block !important;
    @extend .pagination-pointed-button;
    border-radius: 0 $pagination-radius $pagination-radius 0;
    margin-left: 18px;

    &:hover::after {
      border-right: 17px solid lighten($pagination-primary-color, 10%);
    }

    &::before {
      content: ""; //removes the arrow
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: -17px;
      width: 0;
      height: 0;
      border-bottom: 17px solid transparent;
      border-top: 17px solid transparent;
      border-right: 17px solid $pagination-primary-color;
      transition: all 0.2s linear;
    }
  }
}
