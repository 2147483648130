// =================================================
// Accordions
// =================================================

.pagebuilder section.accordions {
    position: relative;
    h3 {
        margin-top: rem-calc(30);
    }

    h2{
        text-align: center;
        margin-bottom: 5rem;
        font-size: 1.9rem;
        text-transform: capitalize;
        position: relative;
        &:after{
          content:'';
          position:absolute;
          left:0;right:0;
          top:100%;
          margin: 10px auto;
          width:30%;
          height:3px;
          background: linear-gradient(45deg, $bc2 0%, $bc1 100%);
          @include breakpoint(large up) {
             max-width: 322px;
          }
        }
    }
}
