.services{
  display:flex;
  flex-wrap:wrap;
  flex-direction:row;
  position:relative;

  .services-block{
    position:relative;
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center center;
    background-color: rgba($black,.1);

    @include breakpoint(small up) {
      height:300px;
    }
    @include breakpoint(medium up) {
      height:500px;
    }
    @include breakpoint(large up) {

    }

    .services-content{
      opacity:1;
      position:absolute;
      width:100%;
      height:100%;
      left:0;
      top:0;
      background-color:rgba($bc1, 0.2);
      color:$white;

      @include breakpoint(small up) {
        padding:1.3rem;
      }
      @include breakpoint(medium up) {
        padding: 1.6rem;
      }
      @include breakpoint(large up) {
        padding: 2rem;
      }


      i{
        color:$primary-color;
      }

      h2{
        color:$white;
        // font-weight: 300;

        @include breakpoint(small up) {
          font-size: 1.5rem;
        }
        @include breakpoint(medium up) {
          font-size: 2rem;
        }
        @include breakpoint(large up) {

        }
      }
      .cover-link{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
      }
    }

    &:hover{
      .services-content{
        opacity:1;
        background-color:rgba($bc1, 0.8);
      }
    }

    &-last{
      .services-content{
        opacity:1;
        background-color:$secondary-color;

        a{
          position:absolute;
          bottom:3rem;
        }
      }
    }
  }
}
.pagination{
  background-color:$light-gray;
  overflow:hidden;
  padding:2rem 0;
  margin:0;

  ul{
    margin:0;
    padding:0;
    text-align:center;

    li{
      a{
        color:$tc1;
      }
    }
  }
}
.filters{
  background-color:$light-gray;
  overflow:hidden;
  padding:2rem 0;
  margin:0;
  text-align: center;

  ul{
    margin:0;
    padding:0;

    li{
      list-style: none;
      display:inline-block;
      vertical-align:middle;
      margin: 0 0.5rem;

      a{
        color:$tc1;
        position:relative;
        padding:0 0 0 1.5rem;

        &:before{
          content:"";
          display:inline-block;
          vertical-align: middle;
          height:10px;
          width:10px;
          background-color:transparent;
          border:2px solid $bc1;
          border-radius:50%;
          position:absolute;
          top:15%;
          left:0.5rem;
        }
      }
      &.active{
        a{
          color:$bc1;

          &:before{
            border-color:$secondary-color;
            background-color:$secondary-color;
          }
        }
      }
    }
  }
}
