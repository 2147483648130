.logo-carousel{
  list-style-type: none;
  margin:0 !important;
  padding:0;

  li{
    display:inline-block;
    background-repeat:no-repeat;
    background-position:center center;
		background-size: 70%;

    height:100px;
  }

  .slick-dots{
    text-align:center;
    margin:2rem 0 0 0;

    li{
      height:10px;
      width:10px;
      border-radius:50%;
      display:inline-block;
      cursor:pointer;

      &.slick-active{
        background-color: $primary-color;
      }
    }
  }
}
