// =================================================
// General Content
// =================================================
.pagebuilder{
    overflow-y: hidden;
}

.pagebuilder section.general-content-area {

    .rounded-corner{
        border-top-left-radius: 22px !important;
        margin-right: -0.9375rem !important;
    }

    .icon.video svg {
        display: none;
        height: rem-calc(40);
        width: auto;
        cursor: pointer;
        transition: all 0.15s ease-in;
        
        @include breakpoint(medium up) {
            display: block;
            height: rem-calc(50);
        }

        &:hover {
            transform: scale(1.1);
        }

        .triangle {
            fill: $bc2;
        }

        .round {
            fill: $bc3;
        }
    }

    &.has-media-col {
        .image-col, .video-column {
            @include breakpoint (medium up) {
                position: relative;
            }
        }

        .img-col, .video-col {
            display: block;
            height: auto;

            @include breakpoint (medium up) {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
            }
        }
    }

    .video-col {
        .img-wrap, .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            cursor: pointer;

            &:hover {
                .icon.video svg, 
                + .overlay .icon.video svg {
                    transform: scale(1.2);
                }
            }
        }

        .overlay {
            background-color: rgba($black, 0.25);

            .content {
                text-align: center;
                position: absolute;
                top: 50%;
                width: 100%;
                pointer-events: none;
                transform: translateY(-50%);

                .icon.video {
                    display: inline-block;

                    svg {
                        display: block;
                        height: rem-calc(40);
                        width: auto;
                        transition: all 0.15s ease-in;

                        @include breakpoint(large up) {
                            height: rem-calc(49);
                        }
    
                        // .triangle {
                        //     fill: $bc1;
                        // }
                
                        // .round {
                        //     fill: $white;
                        // }
                    }
                }

                h2 {
                    margin: rem-calc(10) 0;
                    color: $white;

                    @include breakpoint(large up) {
                        margin-top: rem-calc(20);
                    }
                }

                p {
                    margin: 0;
                    color: $white;
                }
            }
        }
    }

    .responsive-embed, .flex-video {
        margin-bottom: 0;
    }

    &.has-media-col .txt-col {
        @include breakpoint(medium up) {
            padding: rem-calc(100) $global-padding;
        }
    }

  .title{
      h2{
          text-align: center;
          margin-bottom: 5rem;
          font-size: 1.9rem;
          text-transform: capitalize;
          position: relative;
          &:after{
            content:'';
            position:absolute;
            left:0;right:0;
            top:100%;
            margin: 10px auto;
            width:30%;
            height:3px;
            background: linear-gradient(45deg, $bc2 0%, $bc1 100%);
            @include breakpoint(large up) {
               max-width: 322px;
            }
          }
      }
  }
 
    &.not-expanded {
        padding: rem-calc(100) 0;
        // position: relative;
        // z-index: 1; 

        @include breakpoint(small down) {
            //padding: rem-calc(40) 0;
        }

        + .not-expanded {
            //padding-top: 0;
         
        }

        &.no-media-col {
            .txt-col {
                padding-bottom: 0;
                padding-top: 0;
            }
        }
        // .img-col{
        //     border-radius: 22px 0 0  0;
        // }

        .text-column{
            border-radius: 0 22px 0  0;
        }
    }
    &.has-bg-img{
        padding: 6rem 0 !important;
        background-size: cover;
        z-index: 1;
       position: relative;
        
    }

    &.has-video-col{
        position: relative;

    }
   
    .txt-col{
        padding: 2rem 0;
       

        @include breakpoint(medium up){
            padding: 4rem 0;
        }
        h3{
            color: $bc1 !important;
            font-size: rem-calc(26);
            font-weight: 500;
        }
        h4{
            font-size: 1.4rem;
            line-height: 1.6rem;
            margin: 1rem 0;
            font-weight: 600;
            color: #5d5d5c;           

            a{
               display: inline-block;
               position: relative;
                &:after{
                    content: '';
                    border-bottom: 2px solid $bc2;
                    position: absolute;
                    bottom: -7px;
                    width: 100%;
                    left: 0;
                }
                
            }
        }
        h5{
            font-size: 0.8rem;
            line-height: 0.8rem;
            font-weight: 600;
            color: $bc1;
            text-transform: uppercase;

        }
        p{
            line-height: 1.5rem;
            color: $bc4;
            font-size: 1rem;

          &.small{
              font-size: 0.9rem;
          }

          @include breakpoint(large up) {
              max-width: 95%;
             // text-align: justify;
          }

          a{
              margin-bottom: 0;
          }

        }
        ul{          
            margin-left: 20px;
            li{
                &:before{
                    content: "\25A0";  
                    color: $bc1;
                    font-weight: bold; 
                    display: inline-block; 
                    width: 1em;                    
                    height: 7px;
                    line-height: 25.6px;
                    font-size: 1.2rem;
                    margin-left: 0;
    
                }
            }
            
        }

        .wysiwyg{
            @include breakpoint (medium down){
                /* Force table to not be like tables anymore */
                table:not(.no-collapse) {
                    thead, tbody, th, td, tr { 
                        display: block;
                    }
                }

                td{
                    width: 100%;
                }
            }
        }
        
    }
    &.text-light{
        h1,h2,h3,h4,p,a{
            color: $white;
        }
        
    }
    &.text-dark{
        h1,h2,h3,h4,p,a{
            color: $bc4;

            &.button {
                color: $white;
            }
        }
    }

    hr {
        display: block;
        border: none;
        background: $bc1;
        height: .4rem;
        max-width: 8rem;
        margin: 0;
        margin-top: 2rem;
        margin-bottom: 3rem;
    }

    // WORDPRESS STANDARD
    // -----------------------------------------------------

    .alignnone {
        margin: 5px 20px 20px 0;
    }

    .aligncenter,
    div.aligncenter {
        display: block;
        margin: 5px auto 5px auto;
    }

    .alignright {
        float:right;
        margin: 5px 0 20px 20px;
    }

    .alignleft {
        float: left;
        margin: 5px 20px 20px 0;
    }

    a img.alignright {
        float: right;
        margin: 5px 0 20px 20px;
    }

    a img.alignnone {
        margin: 5px 20px 20px 0;
    }

    a img.alignleft {
        float: left;
        margin: 5px 20px 20px 0;
    }

    a img.aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .wp-caption {
        background: #fff;
        border: 1px solid #f0f0f0;
        max-width: 96%; /* Image does not overflow the content area */
        padding: 5px 3px 10px;
        text-align: center;
    }

    .wp-caption.alignnone {
        margin: 5px 20px 20px 0;
    }

    .wp-caption.alignleft {
        margin: 5px 20px 20px 0;
    }

    .wp-caption.alignright {
        margin: 5px 0 20px 20px;
    }

    .wp-caption img {
        border: 0 none;
        height: auto;
        margin: 0;
        max-width: 98.5%;
        padding: 0;
        width: auto;
    }

    .wp-caption p.wp-caption-text {
        font-size: 11px;
        line-height: 17px;
        margin: 0;
        padding: 0 4px 5px;
    }

    /* Text meant only for screen readers. */
    .screen-reader-text {
        border: 0;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute !important;
        width: 1px;
        word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
    }

    .screen-reader-text:focus {
        background-color: #eee;
        clip: auto !important;
        clip-path: none;
        color: #444;
        display: block;
        font-size: 1em;
        height: auto;
        left: 5px;
        line-height: normal;
        padding: 15px 23px 14px;
        text-decoration: none;
        top: 5px;
        width: auto;
        z-index: 100000;
        /* Above WP toolbar. */
    }
    .video-col {
        
        width: 100%;       
        cursor: pointer;            

        .video-poster{
            position: relative;           
            background-size: cover !important;
            display: flex;
            justify-content: center;
            align-items: center;
           // padding-bottom: 56.25%;
           // min-height: 15rem;            
            @include breakpoint(medium up){
              //  min-height: 10rem;
            }
            @include breakpoint(medium down){
                margin-bottom: 2rem;
                min-height: 15rem; 
            }
            .content{    
                text-align: center;                
                p{                   
                  color: $white;
                  margin: auto;
                  @include breakpoint(medium up){
                      max-width: 70%;
                     
                  }
                  @include breakpoint(large up){
                    max-width: 60%;
                   
                  }
                }
            }

            &.right-space{
                @include breakpoint(medium up){
                   margin-right: 15px;
                   
                }
            }
        }

        p{
            font-size: rem-calc(18);
            letter-spacing: -0.45px;
            
        }
    
    }
}

.floaty-enabled .pagebuilder section.general-content-area {
    .floaty {
        margin-top: rem-calc(-25);
    }
}

.pull-out-content{
    span {
        color:$secondary-color;
        text-transform:uppercase;
    }
   
}
section{
   // z-index: 1;
   position: relative;
    &.top-small{
       margin-top: -10vh; padding-top: 10vh!important;
       z-index: -1 !important;
       position: static !important;
  
    }
    &.top-medium{
       margin-top: -20vh; padding-top: 20vh!important; 
     //  z-index: -1 !important;
     //  position: static !important;

     &.bg-light-gray{
         z-index: -1;
     }

       &:before{
            content: '';
            display: block;
            height: 85%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: #EDECEB;
            z-index: -1;

       }
    }
    &.top-large{
        margin-top: -40vh; padding-top: 40vh!important; 
        z-index: -1 !important;
        position: static !important;
    }
    &.bottom-small{
      margin-bottom: -10vh; padding-bottom: 15vh!important;
       z-index: -1 !important;
       position: static !important;
    }
    &.bottom-medium{
      margin-bottom: -20vh; padding-bottom: 25vh!important; 
      z-index: -1 !important;
      position: static !important;
    }
    &.bottom-large{
      margin-bottom: -40vh; padding-bottom: 40vh !important;
      z-index: -1 !important;
      position: static !important;
    }
    &.both-small{
      margin-bottom: -10vh; padding-bottom: 10vh!important;
      margin-top: -10vh; padding-top: 10vh!important;
      z-index: -1 !important;
      position: static !important;
    }
    &.both-medium{
      margin-bottom: -18vh; padding-bottom: 18vh !important; 
      margin-top: -18vh; padding-top: 18vh !important;
      z-index: -1 !important;
      position: static !important;
      .txt-col{
          padding: 0 !important;
      }
    }
    &.both-large{
      margin-bottom: -30vh; padding-bottom: 30vh!important; 
      margin-top: -30vh; padding-top: 30vh!important;
      z-index: -1;
      position: static !important;
    }

    table{
        tbody{
            background-color: inherit;

             p{
                max-width: 100% !important;
                color: #1d1d1b;
            }
        }
    }

    &.video-modal-container{
        position: relative;
        z-index: 2;
    }
  }