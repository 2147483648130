// =================================================
// Quicklinks
// =================================================
.quicklink {
  position: relative;
  display: block;

  .icon-image {
    display: block;
    text-align:center;
    margin:0 auto;

    img {
      margin: 0 auto 1rem auto;
      display: block;
      max-width: 100%;
      height: auto;
      
      @include animate(.3s, ease-in-out);
      // max-width:50px;
    }
  }

  .content {
    display: block;
    margin:0 0 ($global-padding*2) 0;

    h4{
      text-transform:none;
      margin:0 0 1.5rem 0;
    }
    p{
      margin:0 0 $global-padding 0;
    }
    a{
      color:$tc1;
    }
  }

  a {
    display: block;
    color:$tc1;

    &:hover {
      img {
        opacity: .8;
      }

      h3 {
        text-decoration: underline;
      }
    }
  }
}
