// =================================================
// FEATURED BLOCK - IMAGE or VIDEO w/ CONTENT
// =================================================


// Class
// ------
.text-image-block {
	position: relative;


	// Content wrapper
	.wrapper {



		// Image
		.image {
			// @include flexImg;
			max-width: 100%;
			height: auto;

			position: relative;

			img {
				//width: 100%;
				//height: 100%;
				//object-fit: cover;
			}
		}



		// Content
		.content {
			position: relative;
			@include breakpoint(medium down) {
				text-align: left;
			}

			h3 {
				margin-bottom: 1rem;
				color: $bc1;
			}
			h4 {
				margin-top: -1rem;
				margin-bottom: 1rem;
			}

			p {
				margin-bottom: 2rem;
			}
		}
	}
}




// IE9 Fixes

.ie-9 {
	.video-play {
		display: none !important;
	}
}
