// =================================================
// Table Content
// =================================================

.pagebuilder section.table-content {
    position: relative;
    padding: rem-calc(60) 0;

    h2{
      text-align: center;
      margin-bottom: 5rem;
      font-size: 1.9rem;
      text-transform: capitalize;
      position: relative;
      &:after{
        content:'';
        position:absolute;
        left:0;right:0;
        top:100%;
        margin: 10px auto;
        width:30%;
        height:3px;
        background: linear-gradient(45deg, $bc2 0%, $bc1 100%);
        @include breakpoint(large up) {
           max-width: 322px;
        }
      }
    }
  table {
      display: table;
      width: 100%;

      thead {
          background-color: #E0DED8;
          border: 0;       
          //background: linear-gradient(72.37deg, #E64215 0%, #EF7D00 100%);
          th, th * {
              color: $black;
              border-top: 2px solid #E64215;
              text-transform: uppercase;

              a{
                position: relative;
                min-width: 200px;
                padding: 0;
                line-height: 1.9rem;
                text-align: left;             
                                   
                  svg{                  
                    position: absolute;
                    top: -5px;
                    height: 35px;
                    fill: #fff;
                    height: 35px;
                    transition: all 0.3s ease;
                    
                  }

                  &:hover{
                    svg{
                      transform: translateX(10px);
                    }
                  }
                
              }

         
           
          }
          
      }

      tr {
          th, td {
              padding: 1rem 1.5rem;
              border-right: 1px solid rgba(#575756,0.1);

              span {
                  display: block;
                  color: $bc1;
                  font-weight: bold;
              }
          }          
      }

      tbody{
        tr{
          &:last-child{
            border-bottom: 2px solid #E64215;
          }

          &:nth-child(even){
            background-color: rgba(#E0DED8,0.2);
          }        
        }
      }

      &.table-info{
        margin-bottom: 0;

        thead{
          background-color: #1d1d1b;          
          tr{
            border: 0;
          }
          th, th *{
            border-top: 0;
            color: $white;
            text-transform: uppercase;
          
          }
         
        }
      }
  }
}
