.blog{
   
        .archive-list{   
            position: relative;
            overflow: hidden;

            &:after{
                content: '';              
                display: block;
                height: 85%;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                background-color: #EDECEB;
                z-index: -1;

                @include breakpoint(medium up){
                    margin-top: 17rem;
                    height: 90%;
                }

            }
           
        }

        .row .card-col:first-child {
            .post .image{
                border-radius: 0;
            }
        }

        .card.post{
            .content-wrapper{
                border-left: 0;
                .bg-grey{
                    background-color: $white !important;
                   
                }
            } 

        } 
    
}