// =================================================
// CTA
// =================================================
.cta{
   z-index: 1;
   position: relative;
    .row{
        padding: 2rem 0;  
        z-index: 1;  
        @include breakpoint(medium up) {
            padding: 3.5rem 0;  
        }   
    }
    .dark-gray{
        background-color: $bc4;
        color: $white;
        border-left: 13px solid;   
        border-image-source: linear-gradient(180deg, #E64215 0%, #EF7D00 100%);
        border-image-slice: 1;    
        border-right: 0;
        border-bottom: 0;
        border-top: 0;		
          
    }

    p{
        font-size: rem-calc(18);
        line-height: 1.4;
        font-weight: 500;
        @include breakpoint(medium down){
            margin-bottom: 2rem;
        }
    }
    .cta-button{
        display: flex;
        align-items: center;
    }

    .scroll{
        margin-bottom: 0;
        @include breakpoint(medium up){
            margin-bottom: 0;
        }
    }
}