// =================================================
// Contact
// =================================================

.pagebuilder section.contact {
    position: relative;

    &.light-gray,
    &.white {
        .content {
            color: $bc4;
        }
    }
    &.primary,
    &.secondary {
        .content {
            color: $bc4;
        }
    }

    .pickup-form {
        .row {
            margin-bottom: 1rem;
        }

        .wpcf7-field-group-remove,
        .wpcf7-field-group-add {
            background: $bc1;
            color: #fff;
            height: 15px;
            width: 15px;
            border-radius: 50%;
            margin-right: -10px;
            margin-left: 15px;
        }

        .wpcf7-select {
            padding: 0 0 0 1rem !important;
            height: 48px !important;
        }
    }

    .content {
        z-index: 0;

        h2 {
            color: #e74315;
            font-size: rem-calc(30);
            text-transform: none;
            font-weight: 500;
            letter-spacing: -0.25px;
            line-height: 2;
        }
        p {
            font-size: rem-calc(15);
            line-height: 1.6;
        }

        a {
            color: inherit;

            &.address,
            &.phone,
            &.email {
                display: block;
                margin-bottom: $global-padding * 0.5;
                padding: $global-padding * 0.5 0;

                svg {
                    fill: $white;
                }
            }
        }

        h2,
        p {
            margin-bottom: rem-calc(20);
        }

        form {
            margin-top: $global-padding;

            input,
            [type="text"],
            [type="password"],
            [type="date"],
            [type="datetime"],
            [type="datetime-local"],
            [type="month"],
            [type="week"],
            [type="email"],
            [type="number"],
            [type="search"],
            [type="tel"],
            [type="time"],
            [type="url"],
            [type="color"],
            textarea,
            select {
                margin-bottom: 0;
                border: 0;
                padding: 1.5rem;
                border-left: 3px solid $bc1;
                color: $bc4;
            }

            input[type="submit"] {
                @extend .button;
                display: block;
                min-width: rem-calc(249);
                float: none;

                @include breakpoint(medium up) {
                    float: right;
                    //margin-right: 2rem;
                }
            }

            .wpcf7-textarea {
                width: 100% !important;
            }

            .col-2 {
                @include breakpoint(medium up) {
                    width: 47%;
                    float: left;
                    margin-bottom: 1rem;
                    margin-right: 10px;
                }
                @include breakpoint(large up) {
                    width: 47%;
                    float: left;
                    margin-bottom: 1rem;
                    margin-right: 10px;
                }
            }
            textarea {
                @include breakpoint(medium up) {
                }
            }
        }

        .screen-reader-response,
        .wpcf7-validation-errors {
            font-weight: 800;

            ul {
                display: none;
            }
        }
    }
}

// Global Form Styles
.wpcf7 {

    // Upload Fields

    .career,
    .file {
        background-color: rgba($white, 0.5);
        clear: both;
        padding: 0.5rem;
        border-left: 3px solid $bc1;
        @include breakpoint(medium up) {
            padding: 1.5rem;
        }

        label {
            color: #1d1d1b;
            font-weight: 500;
            font-size: rem-calc(15);
        }

        input[type="file"] {
            border: 0 !important;
            padding: 0 !important;
        }

        // .wpcf7-file {
        //     position: absolute;
        //     width: 1500px;
        //     height: 1000px;
        //     right: 0;
        //     bottom: 0;
        //     outline: none !important;
        //     cursor: pointer;
        //     transition: all 0.15s ease-in;
    
        //     &:before {
        //         content: "CHOOSE FILE";
        //         display: block;
        //         width: 160px;
        //         height: 40px;
        //         line-height: 40px;
        //         text-align: center;
        //         position: absolute;
        //         right: 0;
        //         bottom: 35px;
        //         color: #fff;
        //         font-size: 16px;
        //         background: linear-gradient(180deg, #e64215 0%, #ef7d00 100%);
        //     }
    
        //     &:hover:before {
        //         opacity: 0.8;
        //     }
        // }

        .wpcf7-not-valid-tip {
            width: 180px;
            height: 30px;
            background-color: transparent;
            color: $bc1;
            position: absolute;
            bottom: 0;
        }

        input[type="checkbox"] {
            border-radius: 50%;
        }
        .wpcf7-checkbox {
            .wpcf7-list-item {
                margin: 5px;
            }
            .wpcf7-list-item-label {
                margin-left: 5px;
            }
        }
    }
}
