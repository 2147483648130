.page-feature-section{
  background-color:$light-gray;

  .feature-card{
    background-color:$bc1;
    color:$white;
    border:0;
    border-radius:20px;
    flex-grow:1;

    .feature-img{
      min-height:14rem;
      background-size:cover;
      background-repeat:no-repeat;
      background-position:center center;
      position:relative;
      border-radius:20px 20px 0px 0px;

      a{
        position:absolute;
        width:100%;
        height:100%;
      }
    }
    .card-details{
      padding:2rem;
      color:$tc1;

      span{
        color:$tc1;

        a{
          color:$secondary-color;
        }
      }

      h3{
        margin:0 0 1rem 0;
        text-transform:none;
        color:$white;
      }
      .cta{
        margin:1rem 0 0 0;
        // @extend %underline-cta;
        // @extend %underline-cta-dark-blue;
      }
    }
  }
  .columns{
    display:flex;
    flex-grow:1;

    @include breakpoint(small up) {
      margin:0 0 1rem 0;
    }
    @include breakpoint(medium up) {
      margin:0;
    }
    @include breakpoint(large up) {
    }
  }
}
.page-features{
  display:flex;

  @include breakpoint(small up) {
    align-content:stretch;
    flex-wrap:wrap;
  }
  @include breakpoint(medium up) {
    flex-grow:1;
    flex-wrap:nowrap;
  }
  @include breakpoint(large up) {
  }

  .news-card-twitter{
    background-color:$twitter;
    color:$white;
    flex-grow:1;
  }
}
