.vivid_change_table{
    overflow: auto;

    table{
        width: 100%;
        height:85%;
        border-collapse: collapse;

        th{
            text-align: left;
            color: #EF7D00;
            background-color: #2C2C2C;
            padding: 5px 10px 5px 10px;
        }

        td {
            padding: 5px 10px 5px 10px;
        }

        tr{
            border-bottom: 1px solid var(--primary-grey, #575756);
        }
    }
}