// =================================================
// Resources
// =================================================
.resources{
    position: relative;
    &:after{
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #EDECEB;
        z-index: -1;

        @include breakpoint(medium up){
            height: 100%;
            top: 31rem;
        }
    }
}