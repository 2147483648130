/*!
Theme Name: Custom Theme
Theme URI: https://www.vividcreative.com
Author: Vivid Creative
Author URI: https://www.vividcreative.com
Description: Custom Theme for Client
Version: 1.0.0
Text Domain: customtheme
Tags: custom-theme
*/
