// =================================================
// MOTION UI CUSTOM ANIMATIONS
// =================================================

// Fade
// ------------------
.animate-fade-in {
    @include mui-animation(fade(0,1), slide);
    animation-duration: 1s;
}


// Zoom
// ------------------
.animate-zoom {
    @include mui-animation(zoom);
    animation-duration: 1s;
}