// Allows for object-fit on images with ie fallback
.img-wrap {
    overflow: hidden;

    &.compat-object-fit {
        background-size: cover;
        background-position: center center;

        .img-obj {
            opacity: 0;
        }
    }

    .img-obj {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

// Adds slight zoom on hover if within a link
a {
    .img-wrap, &.img-wrap {
        .img-obj {
            transition: transform 0.3s ease-in-out;

            &:hover {
                transform: scale(1.01);
            }
        }
    }
}

// .icon svg {
//     use {
//         stroke: #0f0;
//     }
// }

// svg {
//     *, g, path {
//         stroke: inherit;
//     }
// }
