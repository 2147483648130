
@font-face {
    font-family: 'RidleyGrotesk';
    src: url('fonts/RidleyGrotesk-Light.eot');
    src: url('fonts/RidleyGrotesk-Light.eot?#iefix') format('embedded-opentype'),
        url('fonts/RidleyGrotesk-Light.woff2') format('woff2'),
        url('fonts/RidleyGrotesk-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RidleyGrotesk';
    src: url('fonts/RidleyGrotesk-Medium.eot');
    src: url('fonts/RidleyGrotesk-Medium?#iefix') format('embedded-opentype'),
        url('fonts/RidleyGrotesk-Medium.woff2') format('woff2'),
        url('fonts/RidleyGrotesk-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RidleyGrotesk';
    src: url('fonts/RidleyGrotesk-Regular.eot');
    src: url('fonts/RidleyGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
        url('fonts/RidleyGrotesk-Regular.woff2') format('woff2'),
        url('fonts/RidleyGrotesk-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RidleyGrotesk';
    src: url('fonts/RidleyGrotesk-SemiBold.eot');
    src: url('fonts/RidleyGrotesk-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('fonts/RidleyGrotesk-SemiBold.woff2') format('woff2'),
        url('fonts/RidleyGrotesk-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


h1, .h1, h2, .h2, h3, .h3, h4, .h4, p, .p {
    line-height: 1.2;
}

h1, .h1 {
    margin-bottom: rem-calc(30);
}

h2, .h2 {
    margin: 0 0 rem-calc(16);
    text-transform: uppercase;
    font-size: rem-calc(38);
    font-weight: 800;
    line-height: 1.2;
    
    @include breakpoint(medium up) {
        font-size: rem-calc(42);
    }

    @include breakpoint(large up) {
        font-size: rem-calc(50);
    }
}

h3, .h3 {
    margin: rem-calc(10) 0 rem-calc(30);
    font-size: rem-calc(38);
    font-weight: 800;
}

h4, .h4 {
    font-size: rem-calc(18);
    font-weight: 800;
}

p, .p {
    letter-spacing: 0.5px;
    font-size: rem-calc(16);
   
}

.pretitle {
    text-transform: uppercase;
   
}

.wysiwyg {
    ul {
        margin: rem-calc(36) 0 0;
        list-style: none;

        li {
            position: relative;
            margin-bottom: 1.2em;
            padding-left: 1.75em;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 0.8em;
                height: 1.6em;
                background-image: url('images/icon.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
}