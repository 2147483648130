// =================================================
// Cards
// =================================================
.cards{
    h2{
        text-transform: capitalize;
        background: -webkit-gradient(linear, left top, left bottom, from(#E64215), to(#EF7D00));
        background: linear-gradient(to bottom, #E64215 0%, #EF7D00 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 400;
        line-height: 1;
        margin-bottom: 2rem;
        font-size: rem-calc(30);
    }

    &.offset-cards{
        .cards-block{   
            
            @include breakpoint(medium up){
                position: relative;
                padding-top: 11vh;               
                
            }
            &:after{
                @include breakpoint(medium up){
                    content: '';
                    display: block;
                    height: 75%;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 100%;
                    background-color: $white;
                    z-index: -1;                    
                }
            }

               

               > .column{
                    @include breakpoint(medium up){
                        z-index: 1;
                        margin-top: -11vh;
                        margin-bottom: 3rem;
                    }                
                }

               

            
        }
    }
}