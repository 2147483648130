// =================================================
// show the lauyout/design grid
// =================================================

#bg-grid {
    position: fixed;
    top:0;
    height: 100%;
    overflow: hidden;
    z-index: 1;
		opacity: 0.5;
		pointer-events:none;
		width: 75rem;
		left: 50%;
		margin-left: -37.5rem;

		&.expanded{
			left:0;
			width: 100%;
			margin-left: 0;
		}

      div{
        position: relative;
        height: 100vh;
        overflow: hidden;
        outline: 0.25px solid rgba(#000000, .1);

          &:before, &:after{
            content: " ";
            position: absolute;
            top: 0;
            bottom: 0;
          }

					//loop breakpoints
          &:before{
            right: rem-calc(map-get($grid-column-gutter, small)/2);
            left: rem-calc(map-get($grid-column-gutter, small)/2);
            background: rgba(red, 0.1);
          }

          &:nth-child(odd):before{
            right: rem-calc(map-get($grid-column-gutter, small)/2);
          }
          &:nth-child(odd):after{
            left: rem-calc(map-get($grid-column-gutter, small)/2);
          }
          &:nth-child(even):after{
            right: rem-calc(map-get($grid-column-gutter, small)/2);
          }
          &:nth-child(even):before{
            left: rem-calc(map-get($grid-column-gutter, small)/2);
          }

      }

  }
