// =================================================
// PARALLAX
// =================================================

.pagebuilder section.parallax {
	background-color: $dark-gray;

	&.without-content {
		height: rem-calc(750);
		max-height: 60vh;

		.image:after {
			opacity: 0;
		}
	}

	.image {
		background-position: center;
		background-size: cover;
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: 0;
		background-attachment: fixed;
		
		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: $black;
			opacity: 0.5;
			transition: opacity 0.2s ease-in-out;
		}
	}

	.content {
		padding: rem-calc(135) 0;
		color: $white;
		text-align: center;
		transition: all 0.2s ease-in-out;

		@include breakpoint(medium up) {
			padding-left: rem-calc(80);
			padding-right: rem-calc(80);
		}

		@include breakpoint(large up) {
			text-align: left;
		}

		> * {
			position: relative;
			z-index: 9;
		}

		h2 {
			line-height: 1.2;
			text-transform: uppercase;
		}
	}

	&.video {
		cursor: pointer;

		.column {
			@include breakpoint(large up) {
				margin-left: 58.33333%;
			}
		}

		.content {
			@include breakpoint(large up) {
				padding-right: rem-calc(10);
			}

			.play-button {
				width: rem-calc(60);
				height: rem-calc(60);
				margin: rem-calc(5);
				background-image: url('images/play.svg');
				background-position: top;
				background-repeat: no-repeat;
				background-size: 100% auto;
				transition: all 0.2s ease-in-out;
	
				@include breakpoint(large up) {
					position: absolute;
					top: 50%;
					left: 33.333%;
					width: rem-calc(120);
					height: rem-calc(120);
					margin: rem-calc(-60) 0 0 rem-calc(-60);
				}
			}
		}

		&.with-content {
			&:after {
				left: 50%;

				@include breakpoint(large up) {
					top: 50%;
				}
			}
		}

		&:hover {
			.image:after {
				opacity: 0.6;
			}

			.content {
				@include breakpoint(large up) {
					padding-left: rem-calc(10);
					padding-right: rem-calc(80);
				}

				.play-button {
					width: rem-calc(70);
					height: rem-calc(70);
					margin: 0;

					@include breakpoint(large up) {
						width: rem-calc(130);
						height: rem-calc(130);
						margin: rem-calc(-65) 0 0 rem-calc(-65);
					}
				}
			}
		}
	}
}

.modal {
	position: fixed;
	opacity: 0;
	z-index: -999999;
	transition: all 0.3s ease-in-out;

	.overlay {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: rgba($black, 0.8);

		&:before {
			content: 'X';
			position: absolute;
			right: $global-padding;
			top: $global-padding;
			padding: 0.5em 0.6em 0.4em;
			background: $bc1;
			border-radius: 50%;
			color: $white;
			font-weight: 800;
			line-height: 1;
		}
	}

	iframe {
		position: absolute;
		left: 10%;
		top: 10%;
		width: 80%;
		height: 80%;
		transform: scale(0.5);
		transition: all 0.3s ease-in-out;
	}

	&.active {
		opacity: 1;
		z-index: 999999;

		iframe {
			transform: none;
		}
	}
}

html.ios {
	.pagebuilder section.parallax .image {
		background-attachment: initial;
	}
}
