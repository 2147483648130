.archive_filter{
  //  background: $light-gray;
    padding: $global-padding 0;

 //   text-align: center;

 form{
    border-radius: 3px;
    background-color: #E0DED8;
    height: 39px;
    @include breakpoint(medium up){
        max-width: 350px;
    }
 }

    label{
       font-size: rem-calc(14);
        font-weight: 500;
        padding-left: .5rem;
        background-color: rgba( #575756,0.1);
        position: relative;
        height: 39px;
        line-height: 39px;  
        margin: 0;    
    }
    select{
        display: inline-block;
        width: auto;        
        min-width: 75%;
        background-color: #E0DED8;
        border: 0;
        position: absolute;
        right: 0;
    }

    .button{
        margin:0;
        display: none !important;
    }
}