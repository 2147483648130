// =================================================
// 10 grid structure
// =================================================

$sizes: (
    small,
    medium,
    large
);

$grids: (
    10
);



    @each $size in $sizes{

        .grid-10{
        
            @include breakpoint($size up) {
            
                @each $grid in $grids{

                    @for $i from 1 through $grid{

                        .#{$size}-#{$i}{
                            width: percentage($i/$grid);
                        }

                    }

                }
            }

        }
 
    }
