// =================================================
// Featured
// =================================================

.pagebuilder section.featured {
    position: relative;
    img{
        margin-bottom: 2rem;
        @include breakpoint(medium up){
        }
    }
   
    h2{
        text-transform: capitalize;
        background: linear-gradient(to bottom, $bc2 0%, $bc1 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 400;
        line-height: 1;
        margin-bottom: 2rem;
    }
    p{
        line-height: 1.57;
        @include breakpoint(medium up){
            max-width: 70%;           
        }
        @include breakpoint(large up){
            max-width: 690px;
        }
    }

    .text-left{
        text-align: left;
    }
    .text-center{
        text-align: center;
        p{
            @include breakpoint(medium up){
                margin: auto;
            }
        }
       
    }

    &.light-gray{
        background-color: $light-gray;
    }

    &.both-large{
        @include breakpoint(medium up){
          padding-bottom: 20vh !important;
        }
    }
}