// =================================================
// Image Slider
// =================================================
.arrow-right{
	display: block;
    width: 0;
    height: 0;
    border: inset 0.5375rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #000;
}

.arrow-left{
	border: inset 0.5375rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #000 transparent transparent;
}

.pagebuilder section.image-slider {
	position: relative;
	&:after{
		@include breakpoint(medium up){        
            content: '';
            display: block;
            height: 85%;
            position: absolute;
            top: 30%;
            left: 0;
            width: 100%;
            background-color: #EDECEB;
            z-index: -1;
        }
	}

	.slider {
		width: 100%;
		clear: both;

		.slick-slide {
			padding: 0 rem-calc(20);
			cursor: grab;
			outline: none;

			.img-wrap {
				position: relative;
			}

			* {
				cursor: grab;
			}

			&:active {
				cursor: grabbing;

				* {
					cursor: grab;
				}
			}

			.slide.intro {
				width: 90vw !important;
				padding: 5vw rem-calc(20) 0 8.333vw;
				
				@include breakpoint(medium up) {
					width: 50vw !important;
				}

				@include breakpoint(large up) {
					width: 33.333vw !important;
				}

				h2:after {
					content: '';
					display: inline-block;
					width: 0.5em;
					height: 0.5em;
					margin-left: 0.5em;
					border: solid;
					border-width: 0.1em 0.1em 0 0;
					color: $medium-gray;
					transform: translateY(-0.1em) rotate(45deg);
					transition: all 0.15s ease-in-out;

					@include breakpoint(medium up) {
						display: none;
					}
				}

				&:hover {
					h2:after {
						margin-left: 0.6em;
					}
				}
			}

			.img-wrap {
				//position: relative;
				

				.img-obj {
					width: 100%;
					min-height: rem-calc(300);
					position: relative;

					@include breakpoint(medium up) {
						width: auto;
						height: rem-calc(500);
					}
				}

				.caption {
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					padding: $global-padding $global-padding $global-padding/2;
					background: rgba($black, 0.7);
					color: $white;
				}
			}
		}
	}

	.slider-controls {
		padding: rem-calc(15) rem-calc(10);
		text-align: center;

		

		button {
			padding: rem-calc(15) rem-calc(20);
			cursor: pointer;

			* {
				cursor: pointer;
			}

			svg{
				fill: $bc1;
				width: 24px;
				height: 24px;
			}

			.icon svg {
				width: auto;
				height: rem-calc(30);
				transition: all 0.3s ease-in-out;

				* {
					fill: $bc1;
					stroke: $bc1;
					transition: all 0.3s ease-in-out;
				}
			}

			&.prev {
				svg{
					transform: rotate(0deg);
				}
				
			}

			&:hover .icon svg {
				transform: scale(1.1);
			}

			// &:hover .icon svg * {
			// 	fill: transparent;
			// }
		}
	}

	& + .cta{
		padding-top: 0;
	}
}

