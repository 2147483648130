// =================================================
// HERO
// =================================================

#hero {
  position: relative;
  padding-top: rem-calc(100);
  @include breakpoint(medium up) {
     padding-top: rem-calc(160);
  }

    video{
        height:100%;
        width:auto;

        @include breakpoint(medium up) {
            display:block;
        }

        @include breakpoint(large up) {
            display:block;
            height:auto;
            width:100%;
        }
    }

  // GENERAL HERO RULES

  // Content
  .content {
      margin-top: rem-calc(85);
      @include animate(.3s, ease);

      .category {
          right: 0;
          top: 0;
          padding: $global-padding*0.5 $global-padding*1.5;
          background-color: $dark-gray;
          color: $white;
      }

      // Pretitle
      .pretitle{
        text-transform: none;
        font-size: 1.4rem;
        letter-spacing: -0.5px;
        line-height: 1.4rem;
        margin-bottom: 1rem;
        display: block;
     }

      h2 {
          font-size: rem-calc(22);
          font-weight: 800;
          text-transform: uppercase;
          line-height: 1.15;
      }

      // Title
      h1 {
          margin-bottom: rem-calc(42);
          font-size: rem-calc(30);
          font-weight: 300;
          line-height: 1.15;
          
          @include breakpoint(medium up) {
              font-size: rem-calc(42);
          }

          @include breakpoint(large up) {
              font-size: rem-calc(50);
          }
      }

      // Intro
      h4 {
          margin-bottom: rem-calc(30);
          font-size: rem-calc(17);
          font-weight: 400;
          line-height: 1.5;
          max-width: 100%;
          @include breakpoint(large up) {
              max-width: 65%;
          }
      }

      h1 + h4 {
          margin-top: rem-calc(-22);
      }

      a:not(.button) {
          color: inherit;
      }

      .buttongroup {
          display: block;
          width: 60%;

          @include breakpoint(medium up) {
              width: auto;
          }
      }
  }

    // Background Image
    .hero-image-wrapper {
        background-color: $bc4;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        overflow: hidden;

        .hero-image {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-repeat: no-repeat;
            background-size: cover;
            animation: heroImage 12s ease;
            animation-fill-mode: both;
        }

        .hero-image-video {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-repeat: no-repeat;
            background-size: cover;

            /*video{
                height:100%;
                width:auto;

                @include breakpoint(medium up) {
                    display:block;
                }

                @include breakpoint(large up) {
                    display:block;
                    height:auto;
                    width:100%;
                }
            }*/
        }
    }

    &:not(.no-overlay) {
        .hero-image-wrapper .hero-image:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        .hero-image-wrapper .hero-image-video:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    // TEXT VARIATIONS

    &.light-text {
        color: $white;

        .hero-image-wrapper {
            background-color: #EDECEB;

            .hero-image-video {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-repeat: no-repeat;
                background-size: cover;
    
                /*video{
                    height:100%;
                    width:auto;

                    @include breakpoint(medium up) {
                        display:block;
                        height:auto;
                        width:100%;
                    }

                    @include breakpoint(large up) {
                        display:block;
                        height:auto;
                        width:100%;
                    }
                }*/
            }
        }

        &:not(.no-overlay) {
            .hero-image-wrapper .hero-image:after {               
                background: linear-gradient(to left, #575756 0%, rgba(80,80,80,0) 100%);
            }

            .hero-image-wrapper .hero-image-video:after {               
                background: linear-gradient(to right, #575756 0%, rgba(80,80,80,0) 100%);
            }
        }
    }

    &.dark-text {
        color: $black;

        .hero-image-wrapper {
            background-color: $white;

            .hero-image-video {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-repeat: no-repeat;
                background-size: cover;
    
                video{
                    height:100%;
                    width:auto;

                    @include breakpoint(medium up) {
                        display:block;
                    }

                    @include breakpoint(large up) {
                        display:block;
                        height:auto;
                        width:100%;
                    }
                }
            }
        }

        &:not(.no-overlay) {
            .hero-image-wrapper .hero-image:after {
                background: linear-gradient(180deg, rgba($white, 0.4) 0%, rgba($white,0) 100%);
            }

            .hero-image-wrapper .hero-image-video:after {
                background: linear-gradient(180deg, rgba($white, 0.4) 0%, rgba($white,0) 100%);
            }
        }
    }

  // HEIGHT VARIATIONS

  &.medium-height {
      @include breakpoint(medium up) {
          min-height: rem-calc(625);
      }

      .content {
          margin: rem-calc(20) 0 rem-calc(120);

          @include breakpoint(medium up) {
              margin-bottom: rem-calc(60);
          }

          @include breakpoint(large up) {
              margin-top: rem-calc(85);
          }
      }

      video{
        height:100%;
        width:auto;

        @include breakpoint(medium up) {
            display:block;
            height:100%;
            width:auto;
        }

        @include breakpoint(large up) {
            display:block;
            height:auto;
            width:100%;
        }
    }
  }

  &.full-height {
      @include breakpoint(medium up) {
          min-height: 100vh;
      }

      .content {
          margin-bottom: rem-calc(85);

          @include breakpoint(medium up) {
              margin-top: 12vh;
          }

          @include breakpoint(large up) {
              margin-top: 25vh;
          }
      }

      video{
        height:100%;
        width:auto;

        @include breakpoint(medium up) {
            display:block;
            height:100%;
            width:auto;
        }

        @include breakpoint(large up) {
            display:block;
            height:auto;
            width:100%;
        }
    }
  }

  &.single{
    .row-page-title{
        position: relative;
        z-index: 1;
    }
    .hero-image-wrapper{
        z-index: 0;
    }
    .content{
        .cat{
            color: $white;
            font-size: rem-calc(22);
            font-weight: 300;
            .category{
                background-color: transparent !important;
                padding: 0 !important;            
            }
            .pretitle{
                display: inline-block !important;
                @include breakpoint(medium up){
                    margin-left: 0.75rem;
                }

                &:before{
                    @include breakpoint(medium up){
                    content: "•"; color: $white;
                    display: inline-block; width: 1em;
                   // margin-left: -1em
                    }
                }
            }
            
        }
    }    
  }
}

#homehero{
    position: relative;
    padding-top: rem-calc(160);
    min-height: 50vh;
    // display: flex;
    // justify-content: flex-start;
    // align-items: center;
    z-index: 0;
    //margin-top: 85px;
 
    @include breakpoint(large up) {
        min-height: 918px;
    }

    // Background Image
    .hero-image-wrapper {
        background-color: $bc4;
      //background-attachment: fixed;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0px;
        right: 0px;
        z-index: -1;
        overflow: hidden;
       // border-radius: 0 0 10% 0;  

        .hero-image {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-repeat: no-repeat;
            background-size: cover;   

            img{                
                border-radius: 0 0 10% 0;
                position: absolute;   
                right: 0;
                bottom: 0;
                @include breakpoint(medium up) {
                    bottom: 0;
                    right: -21px;             
                }
                @include breakpoint(large up) {
                    min-height: 916px;
                    top: -50%;
                    border-radius: 0 0 10% 0;
                    height: 1568px;
                    right: 0;
                }
                
            }
            
            
        }

        .hero-image-video {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-repeat: no-repeat;
            background-size: cover;   

            img{                
                border-radius: 0 0 10% 0;
                position: absolute;   
                right: 0;
                bottom: 0;
                @include breakpoint(medium up) {
                    bottom: 0;
                    right: -21px;             
                }
                @include breakpoint(large up) {
                    min-height: 916px;
                    top: -50%;
                    border-radius: 0 0 10% 0;
                    height: 1568px;
                    right: 0;
                }
                
            }

            video{
                height:100%;
                width:auto;

                @include breakpoint(medium up) {
                    display:block;
                }

                @include breakpoint(large up) {
                    display:block;
                    height:auto;
                    width:100%;
                }
            }
            
            
        }
 
    }

    .content{
        margin-top: rem-calc(50);
        @include breakpoint(large up) {
            margin-top: rem-calc(100);
        }

        img{
            margin-bottom: 2rem;
            @include breakpoint(medium down){
                max-width: 65%;
            }
        }
        h1{
            font-weight: 300;
            line-height: 1;
        }
        h4{
            font-weight: 500;           
            @include breakpoint(medium up){
                max-width: 60%;
                text-align: justify;
                line-height: 1.3;
            }

        }
        svg{
            margin-top: 4rem;
        }
    }

    // TEXT VARIATIONS

    &.light-text {
        color: $white;

        .hero-image-wrapper {
            background-color: transparent;        
            background-position: bottom center !important;

            .hero-image-video {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-repeat: no-repeat;
                background-size: cover;
    
                video{
                    height:100%;
                    width:auto;

                    @include breakpoint(medium up) {
                        display:block;
                    }

                    @include breakpoint(large up) {
                        display:block;
                        height:auto;
                        width:100%;
                    }
                }
            }
        }
    }

    &.dark-text {
        color: $black;

        .hero-image-wrapper {
            background-color: $white;

            .hero-image-video {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-repeat: no-repeat;
                background-size: cover;
    
                video{
                    height:100%;
                    width:auto;

                    @include breakpoint(medium up) {
                        display:block;
                    }

                    @include breakpoint(large up) {
                        display:block;
                        height:auto;
                        width:100%;
                    }
                }
            }
        }

        &:not(.no-overlay) {
            .hero-image-wrapper .hero-image:after {
                background: linear-gradient(180deg, rgba($white, 0.4) 0%, rgba($white,0) 100%);
            }

            .hero-image-wrapper .hero-image-video:after {
                background: linear-gradient(180deg, rgba($white, 0.4) 0%, rgba($white,0) 100%);
            }
        }
    }
}



@keyframes heroImage {
  0% {
      transform: scale(1.15);
  }

  100% {
      transform: scale(1);
  }
}
@keyframes heroMask {
  0% {
      transform: scale(1);
  }

  100% {
      transform: scale(1);
  }
}


.mobile-padding{
    padding-left: 0.9375rem;
    @include breakpoint(medium up){
        padding-left: 0;
    }
}