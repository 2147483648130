// =================================================
// Blocklinks
// =================================================

.pagebuilder section.blocklinks {
    color: $white;
    position: relative;    
    a:not(.button) {
        color: $white;
    }


	&.expanded {
        padding: 0;
    }

    .row  {

        .column{
            background-color: transparent;        
            position: relative;

        .background-image {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: rem-calc(15);
            right: rem-calc(15);
            background-position: center;
            background-size: cover;
            transition: transform 1.2s ease-in-out;
            @include breakpoint(medium up) {
              //  left: 0.925rem;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, #000 40%, rgba(#000, 0) 100%);
                opacity: 0.69;
            }
        }

        .content {
            position: relative;
            padding: rem-calc(60) 0;
            text-align: center;

            @include breakpoint(medium up) {
                padding: rem-calc(60);
            }

            @include breakpoint(medium up) {
                padding: rem-calc(200) rem-calc(80) rem-calc(150);
            }

            h2 {
                font-weight: 600;
                line-height: 1.2;
                text-transform: none;
                font-size: rem-calc(26);  
                margin-bottom: 1.5rem;              
            }
            P{
                margin-bottom: 2rem;
            }

            &.textareaonly{
                text-align: left;
                padding: rem-calc(40) 0;
                @include breakpoint(medium up) {
                    padding: rem-calc(100) rem-calc(60) rem-calc(100);
                }
                h2{
                    color: $bc2;
                }
                p{
                    color: $bc4;
                }
            }

            .button{
                 &:nth-child(2n){
                     margin-left: 10px;
                 }
            }
        }    

      }
      &.expanded .column .background-image {
            left: 0;
            right: 0;
        }
    }

    a.column {
        display: block;
        position: relative;
        overflow: hidden;

        &:hover {
            // .background-image {
            //     transform: scale(1.025);
            // }

            .button-solid {
                &-red:hover {
                    border-color: $white;
                }

                &-white:hover {
                    border-color: $bc1;
                }
            }
        }
    }
}