.buttongroup {
    display: inline-block;
    margin: 0 rem-calc(-5);
}

.button {
    margin: 1rem rem-calc(5) 0;
    padding: 0.75rem 1.5rem;
    background: linear-gradient(to bottom, $bc2 0%, $bc1 100%);
    background-size: 300% 100%;
    border-left: 0;
    border-radius: 3px;
    color: $white;
    cursor: pointer;
    font-size: rem-calc(14);
    font-weight: 500;
    text-transform: uppercase;
    transition: all .15s ease-in;

    &:hover {
        color: $light-gray;
        transform: scale(1.01);
        opacity: 0.85;
    }

    &.white {
        background: $white;
        border-color: $white;
        color: $black;

        &:hover {
            background: $light-gray;
        }
    }

    &.primary {
        color: $white;

        &:hover {
            color: $light-gray;
        }
    }

    &.arrow {
        position: relative;
        min-width: 200px;
        padding: 0;
        background: none;
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 1.9rem;
        text-align: left;

        svg {
            position: absolute;
            top: 0;
            height: 35px;
            transition: all 0.3s ease;
            
            polygon {
                fill: $bc1;
            }
        }

        &:hover {
            svg {
                transform: translateX(10px);
            }
        }

        &.primary {
            background-color: transparent;
            border-color: transparent;
            color: $bc1 !important;
        }
        
        &.white {
            background-color: transparent;
            border-color: transparent;
            color: $white !important;
        }
    }
}

/*a:hover .button {
    @extend .button:hover;
}

input[type="submit"] {
    @extend .button;
}*/
