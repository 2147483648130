// =================================================
// TEXT BLOCK SECTIONS
// =================================================

// Shared Layout
// ------
.text-block {
	position: relative;


	// 2 Columns Layout
	// ------
	&-two {
		@extend .text-block;
	}

	// 3 Columns Layout
	// ------
	&-three {
		@extend .text-block;
	}

	// 4 Columns Layout
	// ------
	&-four {
		@extend .text-block;
	}
}

