// =================================================
// Margin & Padding Classes
// =================================================

  $sides: (
    left: left,
    top: top,
    right: right,
    bottom: bottom
  );

  $padmar: (
    allwayssmall: (
      small: $global-margin*1.5,
      medium: $global-margin*1.5,
      large: $global-margin*1.5,
      xlarge: $global-margin*1.5,
    ),
    small: (
      small: $global-margin*1.5,
      medium: $global-margin*1.5,
      large: $global-margin*2,
      xlarge: $global-margin*3,
    ),
    large: (
      small: $global-margin*2,
      medium: $global-margin*3,
      large: $global-margin*4,
      xlarge: $global-margin*5,
    ),
    xlarge: (
		small: $global-margin*3,
		medium: $global-margin*4,
		large: $global-margin*5,
		xlarge: $global-margin*6,
    ),
  );

  .no-pad {
	  padding: 0 !important;
  }

	.pad{
    padding: $global-margin*4 0;
    
    @include breakpoint(small down) {
      padding: $global-margin*2 0;

    }
  }


  .gen-pad{
    padding: $global-padding;
  }

  @include breakpoint(medium down) {
    .pad-top-mobile-only{
				padding-top: rem-calc(map-get($grid-column-gutter, small)/1.5);
    }
    .mobile-pad{
      // .column{
				padding-bottom: rem-calc(map-get($grid-column-gutter, small)/1.5);
			// }
    }
  }

  @each $size, $breakpoints in $padmar {

    @each $breakpoint, $amount in $breakpoints {

      @include breakpoint($breakpoint up) {

        .pad-#{$size}{
          padding: $amount 0;
        }
        .mar-#{$size}{
          margin: $amount 0;
        }

				@each $sidex, $side in $sides {
        	.pad-#{$side} { padding-#{$side}: $amount; }
				}

        @each $sidex, $side in $sides {
          .pad-#{$side}-#{$size} { padding-#{$side}: $amount; }
        }
        .mar-#{$size} { margin: $amount; }
        @each $sidex, $side in $sides {
          .mar-#{$side}-#{$size} { margin-#{$side}: $amount; }
        }

      }

    }

  }
