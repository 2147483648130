// =================================================
// HEADER
// =================================================
// .home{
//     #header{       

//         .logo{
//             @include breakpoint(xlarge up) {
//                 display: none;
//               }
//         }
//     }
// }

#header {    
    position: fixed;
    width: 100%;
    z-index: 3;
    animation: slideDown 0.3s ease-in;
    border-top: 10px solid;   
    border-image-source: linear-gradient(135deg,$bc2 0%, $bc1 100%);  
   // border-image-width: 10px;  
    -webkit-border-image-slice: 1;
    border-image-slice: 1;   
    background: rgba($header-bg,0.25);
    font-family: $header-font-family;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  

    #sitewide-notice {
        padding: rem-calc(12) $global-padding;
        margin: 0;
        box-shadow: 0 0 10px rgba(0,0,0,0.15);
        color: $white;
        text-align: center;
        transition: padding 0.15s ease-in-out;

        &.red {
            background: $bc1;
        }

        &.blue {
            background: $bc2;
        }

        &.green {
            background: $bc3;
        }

        strong {
            margin-right: 1em;
        }

        a {
            margin-left: 1em;
            color: inherit;
            text-decoration: underline;
        }
    }

    > .row {        
        transition: padding 0.15s ease-in-out;
    }

    .logo {
        float: left;
        margin: rem-calc(10) 0;
        transition: all 0.15s ease-in;

        @include breakpoint(xlarge up) {
            margin: rem-calc(20) 0;
        }

        &:hover, &:focus, &:active {
            transform: scale(1.025);
        }

        svg, img {
            vertical-align: middle;
            height: rem-calc(39);
            width: auto;
            transition: height 0.3s ease-in-out;

            @include breakpoint(xlarge up) {
                height: rem-calc(49);
            }
        }
    } 


    #main-menu {
        font-weight: 800;       
        display: none;

        @include breakpoint(large down) {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: rem-calc(60) 0;
            background: white;
            border-top: solid rem-calc(139) $bc1;
            overflow-y: auto;
            z-index: -1;
            transform: translateX(100%);
            transition: all 0.3s ease-in-out;
        }

        @include breakpoint(xlarge up) {
            float: right;
            display: block;
        }
    

        ul {
            li {
                position: relative;

                @include breakpoint(large down) {
                    width: 100%;
                }
                

                a {
                    transition: all 0.15s ease-in;

                    @include breakpoint(large down) {
                        margin-right: rem-calc(120);
                        padding: rem-calc(15) rem-calc(30) rem-calc(15) rem-calc(60);
                        color: $black;
                        font-size: 27px;
                        opacity: 0.5;
                        text-align: left;
                    }

                    @include breakpoint(xlarge up) {
                        padding: $global-padding*1.78 $global-padding*1 $global-padding*1.55;
                        color: inherit;
                        text-align: center;
                    }

                    &:hover, &:focus, &:active {
                        text-shadow: 0 0 1em rgba($white, 0.25);
                    }

                    &.active {
                        @include breakpoint(large down) {
                            opacity: 1;
                        }
                    }
                }

                &.current-menu-item, &:hover,&.current-page-ancestor{

                    a{
                        position: relative;
                        &:after{
                            content: '';
                            position: absolute;
                            left: 5px;
                            right: 0;
                            top: 70%;
                            margin: 10px auto;
                            width: 75%;
                            height: 2px;
                            background: linear-gradient(45deg, $bc1 0%, $bc2 100%);
                            margin: auto;
                         //  left: 7px;
                        }
                    }
                    
                     
                    
                }

                &.primary {
                    a {
                        @extend .button;
                    }
                }

                &.secondary {
                    a {
                        //@extend .button.hollow;
                    }
                }

                &.menu-item-has-children {


                    
                    > a:after {
                        content: '';
                        display: inline-block;
                        width: 0.5em;
                        height: 0.5em;
                        margin-left: 0.5em;
                        border: solid;
                        border-width: 2px 2px 0 0;
                        pointer-events: none;
                        transition: all 0.15s ease-in-out;

                        @include breakpoint(large down) {
                            position: absolute;
                            right: rem-calc(90);
                            opacity: 0.6;
                            transform: scale(0.7) translateY(0.3em) rotate(45deg);
                        }

                        @include breakpoint(xlarge up) {
                            transform: translateY(-0.3em) rotate(135deg);
                        }
                    }

                    &:hover, &:focus, &:active {
                        > a {
                            &:after {
                                @include breakpoint(large down) {
                                    color: inherit;
                                }

                                @include breakpoint(xlarge up) {
                                    transform: translateY(-0.2em) scaleY(-1) rotate(135deg);
                                }
                            }
                        }
                    }

                    &.active {
                        > a {
                            @include breakpoint(large down) {
                                opacity: 1;
                                background: none;
                                color: $bc1;
                            }

                            &:after {
                                @include breakpoint(large down) {
                                    opacity: 1;
                                    transform: scale(0.7) translateY(0.3em) rotate(135deg);
                                }
                            }
                        }
                    }
                }

                ul {
                    border: 0;
                    border-radius: rem-calc(5);
                    // box-shadow: 3px 5px 0 $medium-gray;
                    z-index: +9999;
                    text-align: right;
                    transition: all 1s ease-in-out;

                    @include breakpoint(large down) {
                        max-height: 0;
                        overflow: hidden;
                    }

                    @include breakpoint(xlarge up) {
                        display: none;
                        position: absolute;
                        left: 50%;
                        width: rem-calc(180);
                        margin: rem-calc(-10) 0 0 rem-calc(-90);
                        // padding: rem-calc(10) 0;
                        background: $black;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        left: 50%;
                        bottom: 100%;
                        margin-left: -0.5rem;
                        border-style: solid;
                        border-width: 0 0.5rem 0.5rem 0.5rem;
                        border-color: transparent transparent $black transparent;
                    }

                    @include breakpoint(small down) {
                        margin-right: 2rem;
                    }

                    li {
                        a {
                            @include breakpoint(large down) {
                                padding-left: rem-calc(80);
                                color: $black;
                                font-size: rem-calc(22);
                                opacity: 1;
                                text-transform: none;
                                line-height: 1.2;
                            }

                            @include breakpoint(xlarge up) {
                                padding: rem-calc(10) rem-calc(20);
                            }
                        }

                        &:first-child a {
                            padding-top: rem-calc(30);
                            border-radius: rem-calc(5) rem-calc(5) 0 0;
                        }

                        &:last-child a {
                            padding-bottom: rem-calc(30);
                            border-radius: 0 0 rem-calc(5) rem-calc(5);
                        }

                        &:only-child a {
                            border-radius: rem-calc(5);

                        }

                        &:hover, &:focus, &:active {
                            a {
                                @include breakpoint(xlarge up) {
                                    opacity: 0.67;
                                }
                            }
                        }

                        &.active {
                            a {
                                @include breakpoint(large down) {
                                    color: $bc1;
                                }
                            }
                        }
                    }
                }

                &.primary, &.secondary {
                    a {
                        margin: rem-calc(2) $global-padding 0 !important;
                    }
                }

                // &.primary, &.secondary {
                //     & + .primary, & + .secondary {
                //         a {
                //             margin-left: 0;
                //         }
                //     }
                // }

                &:last-of-type {
                    margin-right: 0;

                    a {
                        margin-right: 0 !important;
                    }
                }

                &:hover, &:focus, &:active {
                    ul {
                        @include breakpoint(xlarge up) {
                            display: block;
                        }
                    }
                }

                &.active {
                    ul {
                        @include breakpoint(large down) {
                            transition-duration: 0.3s;
                            max-height: 100vh;
                        }
                    }
                }
            }
        }   
    }

    .home-menu{
        @include breakpoint(xlarge up) {
          float: none !important;
        }

        .menu{            
            &.medium-horizontal{
                @include breakpoint(medium up) {
                  justify-content: center !important;
                }           
            }
        
            li{
                a{
                    @include breakpoint(xlarge up) {
                        padding: $global-padding*1.25 $global-padding*1.5 $global-padding*1.25 !important;
                    } 
                }
            }
            
        }
    }


    @include breakpoint(large down) {
        padding: 0.75rem;

        .topbar-title {
            position: relative;
            width: 100%;

            svg {
                width: 5rem;
            }

            span {
                position: absolute;
                right: 0;
                padding: 0.25rem 0.45rem;
                top: 50%;
                transform: translateY(-50%);

                .menu-icon {
                    &:after {
                        background: $topbar-responsive-color;
                        box-shadow: 0 7px 0 $topbar-responsive-color,
                        0 14px 0 $topbar-responsive-color;
                    }
                }
            }
        }
    }

    // TEXT VARIATIONS

    &.light-text {
        .logo {
            position: relative;
            transition: all 0.1s ease-in-out;

            svg path {
                fill: $white;
                transition: fill 0.3s ease-in-out;
            }
        }

        #main-menu ul li {
            a {
                @include breakpoint(xlarge up) {
                    color: $white;
                }

                // &:hover {
                //     text-shadow: 0 0 1em rgba($white, 0.25);
                // }
            }

            &.primary, &.secondary {
                a {
                    @include breakpoint(large down) {
                        margin: $global-padding rem-calc(60) 0 !important;
                        opacity: 1;
                    }
                }
            }

            &.primary {
                a {
                    @include breakpoint(xlarge up) {
                        background-color: $white;
                        border-color: $white;
                        color: $black;
                    }

                    &:hover {
                        @include breakpoint(xlarge up) {
                            background-color: $bc1;
                            color: $white;
                        }
                    }
                }
            }

            &.secondary {
                a {
                    @include breakpoint(xlarge up) {
                        border-color: $white;
                    }

                    &:hover {
                        @include breakpoint(xlarge up) {
                            background-color: $white;
                            color: $black;
                        }
                    }
                }
            }
        }
    }

    &.dark-text {
        #main-menu ul li {
            a {
                @include breakpoint(xlarge up) {
                    color: $black;
                }

                // &:hover {
                //     text-shadow: 0 0 1em rgba($black, 0.25);
                // }
            }

            ul li a {
                @include breakpoint(xlarge up) {
                    color: $white;
                }
            }
        }
    }
}

@include breakpoint(large down) {
    // #main, #footer {
    //     transition: transform 0.3s ease-in-out;
    // }

    body.show-menu {
        #header {

            #main-menu {
                transform: translateX(0);
            }
        }

        // #main, #footer {
        //     transform: translateX(-100%);
        // }
    }
}


#mobile-menu {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 0;
    z-index: 8;
    overflow: hidden;
    text-align: right;

    @include breakpoint(xlarge up) {
        display: none;
    }

    .overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba($white, 0.8);
        cursor: pointer;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.15s ease-in;
    }

    nav {
        position: relative;
        display: inline-block;
        height: 100%;
        width: 100%;
        padding-top: rem-calc(71);
        background: $bc4;
        border-left: 3px solid transparent;   
        border-image: linear-gradient(180deg, $bc2 0%, $bc1 100%);
        border-image-slice: 1;   
        overflow-y: auto;
        pointer-events: none;
        text-align: left;
        transform: translateX(100%);
        transition: transform 0.15s ease-in;
        min-width: 50%;


        a {
            display: block;
            padding: rem-calc(15) rem-calc(30);
            color: $white;
            font-size: rem-calc(16);
            line-height: 1.333;
            font-weight: 500;

            &:hover, &:focus {
                color: $bc1;
            }
        }


        .menu-item-has-children {
            //position: relative;

            ul.vertical{
                width: 100% !important;
            }

            .sub-menu-wrap{
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            
            
            .sub-toggle{
                width: 10px;
                height: 10px;
                display: block;
                border-left: 2px solid $bc1;  
                border-top: 2px solid $bc1; 
                transform:  rotate(135deg);
                transition: all .25;
                &.active{
                transform:  rotate(225deg);
               }

            }  
                    
            
        }



        ul li ul{
            margin-left: 1rem;

            li a{
                &:before{
                    content: "";
                    width: 10px;
                    height: 5px;
                    display: inline-block; 
                    border-top: 2px solid $bc1; 
                    margin-left: -1rem;
                    margin-right: 1rem;
                }
            }
        }





        ul.menu {

            &:first-child{
                margin-top: 2rem;
            }

            padding: 10px 30px 0 0;

        

            li {
                width: 100%;

                ul {
                    display: none;
                }
            }

            &.secondary {
                li a {
                    color: $light-gray;
                    font-size: rem-calc(14);                   
                    line-height: 1.357;

                    &:hover, &:focus {
                        color: $bc1;
                    }
                }
            }
        }
    }

    .menu-toggle {
        position: fixed;
        right: 0;
        top: 0;
        width: rem-calc(65);
        padding: rem-calc(28) rem-calc(22);
        float: right;
        cursor: pointer;
        margin-top: 20px;
        @include breakpoint(medium up) {
            margin-top: 10px;
        }
    
        @include breakpoint(xlarge up) {
            display: none;
        }
        
        span {
            display: block;
            position: relative;
            width: 100%;
            height: rem-calc(4.5);
            background: $white;
            transition: all 0.15s ease-in;
            clip-path: polygon(24% 0, 100% 0, 100% 100%, 0% 100%);
    
            &:first-child {
                margin-bottom: rem-calc(3);               
            }
            &:nth-child(2) {
                margin-top: rem-calc(3);
                width: 125%;
                left: -25%;
            }
            &:nth-child(3) {
                margin-top: rem-calc(3);
            }
        }
    
        &:hover {
            span {
                background: $bc1;
            }
        }
    }
}

body.menu-open {
    #mobile-menu {
        height: 100%;

        

        .overlay {
            opacity: 1;
            pointer-events: initial;
        }

        nav {
            pointer-events: initial;
            transform: none;
            padding-top: 2.5rem;
        }

        .menu-toggle {
            
            span {
                background: $white;
                clip-path: none;
    
                &:first-child {
                    transform: translateY(12px) rotate(45deg);
                }
    
                &:nth-child(2) {
                    opacity: 0;
                }
    
                &:nth-child(3) {
                    transform: translateY(-3px) rotate(-45deg);
                }
            }
    
            &:hover span {
                background: $bc1;
            }
        }
    }

    &.scrolled{
        .menu-toggle {
            span{
                background: $white !important;
            }
        }

    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}

@include breakpoint(small only) {
    .topbar-responsive-links {
        animation-fill-mode: both;
        animation-duration: 0.5s;
        width: 100%;

        @if $topbar-responsive-animation-type == slide-down {
            animation: slideDown 1s ease-in;
        } @else if $topbar-responsive-animation-type == fade-in {
            animation: fadeIn 1s ease-in;
        } @else {
            animation: none;
        }
    }
}

.nav-up {
   // @include breakpoint(xlarge up) {
         margin-top: -106px;
   // }
    transition: all 0.5s ease-in-out;   
    

}
#header{ 

    &.scrollup{
        background: $white;      
     
         // > .row {
         //     padding: rem-calc(10) 0;
         // }
     
         #main-menu {
             border-top: solid rem-calc(89) $bc1;
     
             @include breakpoint(xlarge up) {
                 border-top: 0;
             }
         }
         .logo svg path {
             fill: $bc4;
         }
     
         #main-menu ul li {
             a {
                 @include breakpoint(xlarge up) {
                     color: $bc4;
                 }
     
                  &:hover {
                       color: $bc1;
                   }
             }
         } 
    }

    &.nav-down{
        transition: all 0.5s ease-in-out;       
    }

}


.scrollup + #mobile-menu{
    .menu-toggle span{
        background: $bc4;
    }
}

body.scrolled {
    #header {
        background: $white;
        border-top: none;
        border-bottom: 5px solid transparent;
        -o-border-image: linear-gradient(180deg, #E64215 0%, #EF7D00 100%);
        border-image: -webkit-gradient(linear, left top, left bottom, from(#E64215), to(#EF7D00));
        border-image: linear-gradient(180deg, #E64215 0%, #EF7D00 100%);
        border-image-slice: 1;
       // box-shadow: 0 0 10px rgba($black,0.2);

        > .row {
            padding: rem-calc(10) 0;
        }

        #main-menu {
            border-top: solid rem-calc(89) $bc1;

            @include breakpoint(xlarge up) {
                border-top: 0;
            }
        }
        .logo svg path {
            fill: $bc4;
        }

        #main-menu ul li {
            a {
                @include breakpoint(xlarge up) {
                    color: $bc4;
                }

                 &:hover {
                      color: $bc1;
                  }
            }
        }     

    }

    #mobile-menu .menu-toggle span{
        background: $bc4;
    }
}

body.logged-in {
    #wpadminbar {
        position: fixed;
    }

    @media screen and (max-width: 782px) {
        #main-menu, .menu-toggle {
            margin-top: 26px;
        }

        #mobile-menu nav {
          //  margin-top: 26px;
            padding-bottom: 26px;
        }
    }

    @media screen and (min-width: 783px) and (max-width: 1063px) {
        #main-menu, .menu-toggle {
            margin-top: 12px;
        }

        #mobile-menu nav {
            margin-top: 0;
            padding-bottom: 32px;
        }
    }
}
