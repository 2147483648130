// =================================================
// MODULE – OFFSET TEXT
// =================================================
.offset-text {
    position: relative;
    z-index: 0;

    @include breakpoint (small down){  
        display: none;
    }


    h2{
        color: #d8d8d8;
        font-size: 6rem;
        line-height: 0rem;       
        text-transform: lowercase;
        opacity: 0.5;
        font-weight: 900;
        margin-bottom: 0;    
         

        @include breakpoint (medium up){           
            font-size: 10rem;
            line-height: 10rem;
            position: absolute;
            letter-spacing: -4px;
        }

        @include breakpoint (large up){
            font-size: 14rem;
            line-height: 14rem;
        }
    

    &.top{
       // margin-top: -12em;

        &.small{
            @include breakpoint (medium up){   
              top: -5rem;
            }
        }
        &.medium{
            
            @include breakpoint (medium up){   
                top: -8rem;
            }
        }
        &.large{
            top: -12rem;
        }
    }
    &.bottom{
        //margin-bottom: -3rem;
        &.small{
            @include breakpoint (medium up){   
                 bottom: -3rem;
            }
        }
        &.medium{
            @include breakpoint (medium up){   
                bottom: -5rem;
            }
         
        }
        &.large{
            @include breakpoint (medium up){   
                bottom: -8rem;
            }
        }
    }
    &.left{
        bottom: 0;

        @include breakpoint (medium up){ 
            left: 0;
        }
   
    }
    &.right{
        bottom: 0;
        @include breakpoint (medium up){ 
            right: 0;
        }       
    
    }
    &.top-bottom{
       // margin-top: -5rem;
      //  margin-bottom: -12rem;
   
        &.small{
            @include breakpoint (medium up){   
                top: -5rem;
                bottom: -5rem;
            }
        }
        &.medium{
            @include breakpoint (medium up){   
                top: -8rem;
                bottom: -8rem;
            }
        }
        &.large{
            @include breakpoint (medium up){   
                top: -12rem;
                bottom: -12rem;
            }
        }
    }
    &.top-left{
      //  margin-top: -5rem;
     //   margin-bottom: -12rem;
     @include breakpoint (medium up){
        left: 0;
     }

        &.small{
            @include breakpoint (medium up){   
                top: -5rem;               
            }
        }
        &.medium{
            @include breakpoint (medium up){   
                top: -8rem;               
            }
        }
        &.large{
            @include breakpoint (medium up){  
                top: -12rem;               
            }
        }
    }
    &.top-right{       

        @include breakpoint (medium up){
            right: 0;
         }

        &.small{
            @include breakpoint (medium up){   
                top: -5rem;                
            }
        }
        &.medium{
            @include breakpoint (medium up){   
                top: -8rem;               
            }
           
        }
        &.large{
            @include breakpoint (medium up){   
                top: -12rem;                
            }
        }
    }
    &.bottom-left{
        @include breakpoint (medium up){
            left: 0;
         }     
        &.small{

            @include breakpoint (medium up){   
                bottom: -3rem;                
            }
            @include breakpoint (large up){                   
                bottom: -4rem;                
            }
        }
        &.medium{
            @include breakpoint (medium up){                   
                bottom: -5rem;                
            }
            @include breakpoint (large up){                   
                bottom: -7rem;                
            }
        }
        &.large{
            @include breakpoint (medium up){  
                bottom: -8rem;               
            }
            @include breakpoint (large up){                   
                bottom: -10rem;                
            }
        }
    }
    &.bottom-right{
        @include breakpoint (medium up){
            right: 0;
         }
        &.small{
            @include breakpoint (medium up){   
                bottom: -5rem;               
            }
        }
        &.medium{
            @include breakpoint (medium up){   
                bottom: -8rem;                
            }
        }
        &.large{
            @include breakpoint (medium up){   
                bottom: -12rem;                
            }
        }
    }
  }  
}