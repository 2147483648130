// =================================================
// TEAM
// =================================================
.teams{
    h2{
        text-transform: capitalize;
        background: -webkit-gradient(linear, left top, left bottom, from(#E64215), to(#EF7D00));
        background: linear-gradient(to bottom, #E64215 0%, #EF7D00 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 400;
        line-height: 1;
        margin-bottom: 3rem;
        text-align: center;
    }
    .team-content{
        border-bottom: 3px solid #E0DED8;
    }

}