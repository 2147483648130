// =================================================
// TESTIMONIALS
// =================================================

.testimonial-slider {
	position: relative;
	text-align: center;


	.slick-dots{
		text-align:center;
		margin:2rem 0 0 0;

		li{
			height:10px;
			width:10px;
			border-radius:50%;
			display:inline-block;
			background-color:$light-gray;
			cursor:pointer;

			&.slick-active{
				background-color:$bc1;
			}
		}
	}


	h3 {
		color: $bc1;
		margin: 0 0 2rem 0;
	}


	ul {
		margin: 2rem 0 0 0;
		list-style: none;

		li {
			outline: none;
			list-style: none;

			cursor: grab;

			&:active {
				cursor: grabbing;
			}
		}
	}


	blockquote {
		border: 0;
		padding: 0;

		p {
			font-size: 1.8rem;
			margin-bottom: 1.5rem;
			text-align: center;
		}
	}

	author {
		font-size: 1.6rem;
		color: $bc1;
		text-align: center;
		display: block;

		
	}
	.img-wrap {
		//position: relative;
		

		.img-obj {			
			//width: 90vw;
			min-height: rem-calc(300);
			position: relative;

			@include breakpoint(medium up) {
				width: auto;
				height: rem-calc(500);
			}
		}
		
	}
	.slider-content{
		max-width: 80%;
		margin: auto;
		text-align: center;
		@include breakpoint(medium up){
		//	max-width: 60%;
			text-align: left;
			padding: 1rem;
		}
		@include breakpoint(large up){
			max-width: 55%;
			text-align: left;
			padding: 2rem;
		}
	}

	// Controls
	.slider-controls {
		display: block;
		@include breakpoint(medium up){
			margin-top: 1.5rem;
		}

	
		

		i, svg {
			display: inline-block;
			width: 1.06rem;
			height: 1.06rem;

			fill: $dark-gray;

			@include animate(.3s, ease-in-out);
		}

		button {
			display: inline-block;
			cursor: pointer;

			margin-right: 1rem;

			&:last-child {
				margin-right: 0;
			}

			// &:hover {
				svg {
					fill: $primary-color;
				}
			// }
		}
	}


}
