// =================================================
// PARTNER LISTING
// =================================================

.pagebuilder section.listing-carousel { 
    position: relative;

    @include breakpoint(medium up){
        &:after{
            content: '';
            display: block;
            height: 85%;
            position: absolute;
            top: 40%;
            left: 0;
            width: 100%;
            background-color: #EDECEB;
            z-index: -1;
        }
    }
   
        
    &.light-gray{
        background-color: #edeceb;
    }

    h2{
        text-transform: capitalize;
        background: -webkit-gradient(linear, left top, left bottom, from(#E64215), to(#EF7D00));
        background: linear-gradient(to bottom, #E64215 0%, #EF7D00 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 400;
        line-height: 1;
        margin-bottom: 2rem;
    }


    .slider-wrapper {
        position: relative;

        .slider {
            list-style-type: none;
            margin: 0 rem-calc(30+20);
            // padding: 0 rem-calc(60);


            .slick-slide {
                display:inline-block;
                background-repeat:no-repeat;
                background-position:center center;
                background-size: 70%;
                color: inherit;
            //    opacity: 0;
                text-align: center;
                transition: all 0.3s ease-in-out;

                &.slick-active {
                    opacity: 1;
                }

                .card.list {
                    transition: all 0.3s ease-in-out;
                   // transform: scale(0.75);

                }

                h3, p {
                 //   opacity: 0;
                    transition: all 0.3s ease-in-out;
                }

                p {
                    font-size: rem-calc(14);
                }
                h5{
                    &.desg{
                        margin-bottom: 0.5rem;
                    }
                }

                &.slick-center {
                    .card.list {
                        transform: scale(1);
                    }

                    h3, p {
                    //    opacity: 1;
                    }
                }
              


                
            }

          

            &-controls {
                text-align: center;
                button {
                    // position: absolute;
                    // top: 0;
                    // height: rem-calc(180);
                    // width: rem-calc(40+30);
                    // padding: rem-calc(40) rem-calc(20);
                    cursor: pointer;
                    text-align: center;

                    svg {
                        transition: all 0.15s ease-in-out;
                        height: rem-calc(24);

                        *, use {
                            transition: all 0.15s ease-in-out;
                        }
                    }

                    &:hover {
                        svg {
                          //  height: rem-calc(30);

                            *, use {
                                fill: $bc4;
                            }
                        }
                    }

                    &.prev {
                        left: 0;
                        transform: rotate(0deg);
                    }

                    &.next {
                        right: 0;
                    }
                }
            }
        }
    }

    .list {     
        height: rem-calc(365);
        box-shadow: none;
        position: relative;   
        overflow: auto;
    
        .img-wrap {
            position: relative;
            height: rem-calc(365);
            width: 100%;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;    
    
          
        }
    
    
        .slidercontent{
            position: absolute;
            bottom: 0;
            left: 0;           
            color: $white;
            padding: 0 1.5rem 1rem;
            z-index: 1;
            text-align: left;
        
            h3{
                font-size: 1.6rem;             
                font-weight: 600;
                margin-top: 0;
                margin-bottom: 0.5rem;
            }
            h5{
                font-size: 0.8rem;
                text-transform: uppercase;
                font-weight: 500;
                margin-bottom: 0;
            }
    
            .content{
                 display: none;
                 transition: 0.5s;
                 font-size: 0.9rem;
                 font-weight: 500;
    
            }
        }
    
    
        &:hover{          
          
            .slidercontent{
                .content{
                    display: block;     
                }
            }
        }
    
      &.slick-slide{
          transform: scale(0.9);
          transition: transform .3s cubic-bezier(.4,0,.2,1);
      }
      &.slick-center{
        //   height: 150%;
          transform: scale(1.1);
          z-index: 1;
          transition: transform .3s cubic-bezier(.4,0,.2,1);
          
      }

     

      .post-grid{
          .card.grid{
              .slidercontent{
                  h3{
                      font-size: rem-calc(20);
                  }
              }
          }
      }
      

        
 }

  // Controls
  .slider-controls {
    display: block;
    margin-top: 1.5rem;

    i, svg {
        display: inline-block;
        width: 1.06rem;
        height: 1.06rem;

        fill: $dark-gray;

        @include animate(.3s, ease-in-out);
    }

    button {
        display: inline-block;
        cursor: pointer;

        margin-right: 1rem;

        &:last-child {
            margin-right: 0;
        }    

        // &:hover {
            svg {
                fill: $primary-color;
            }
        // }
    }
}

 .listing-grid > .row{

    .column{
        @include breakpoint(medium up){
            padding: 0;
        }       
    }
   
    //   .column:nth-child(2){
    //     .grid{
    //         @include breakpoint(medium up){
    //             transform: scale(1.1);
    //             z-index: 1;
    //         }
    //     }
   //   }
  
  }
 
}

.post-grid > .row{
    .column{
        @include breakpoint(medium up){
            padding-right: 0.9375rem !important;
            padding-left: 0.9375rem !important;
        }
    }

   .column:nth-child(2){
        .grid{
            @include breakpoint(medium up){
                transform: none !important;
                
            }
        }
      }
}
