// =================================================
// GLOBAL ELEMENTS - SITE WIDE
// =================================================
body{
  color: #1D1D1B;
}
main{
  overflow-x: hidden;
}
// Remove blue links from iphone
a[href^=tel]{
    color: inherit;
    text-decoration: none;
}

.dark{
	background: $light-gray;
}

.light{
	background: $white;
}


//TODO: update this to work with WYSIWYG or kitchen sink types
.columns{
    table{
        max-width: 100%;
        overflow: auto;
        display: block;
        min-width: 100%;
    }
}

.row.rtl {
  .column { 
      float: right;
  }
}

@each $row in $foundation-palette {
  $label: nth($row, 1); $value: nth($row, 2);

  .bg-#{$label} {
    background: $value;
  }
  .text-#{$label} {
    color: $value;
    *{
        color: $value;
    }
  }
}

a.address .icon {
  height: 1em;
  width: 1em;
  display: inline-block;
  line-height: 1.2;
  vertical-align: text-top;
  margin-right: 1em;
}

a.phone .icon {
  height: 1em;
  width: 1em;
  display: inline-block;
  line-height: 1.2;
  vertical-align: text-top;
  margin-right: 1em;
}

a.email .icon {
  height: 1em;
  width: 1em;
  display: inline-block;
  line-height: 1.2;
  vertical-align: text-top;
  margin-right: 1em;
}

.light-gray{
  background-color: $light-gray;
  color: $bc4;
}

.row{
  z-index: 1;
  position: relative;
}

.wpcf7-not-valid-tip,
.wpcf7-response-output{
  color: $bc2;
}

.pagebuilder section.contact .content .screen-reader-response,
.pagebuilder section.contact .content .wpcf7-validation-errors,
.wpcf7-response-output {
  font-weight: 800;
  padding: 0 rem-calc(15);
  color: $bc2;
  line-height: 1.3;
}
