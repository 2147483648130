// =================================================
// Process Text Nav
// =================================================

.pagebuilder section.process-text-nav-area {
    .txt-col{
        h2{
            color: $bc1;
            text-transform: none;
            font-weight: 500;
            font-size: rem-calc(30);
            line-height: 2;
            letter-spacing: 0;
        }
        p{
            color: $bc4;
            font-size: rem-calc(15);
            line-height: 1.8;
        }
    }

    .subnav-area{
        h3{
            color: $bc1;
            text-transform: none;
            font-size: rem-calc(20);
            line-height: 2;
            letter-spacing: -0.25px;
        }

        .process-subnav{
            // display: flex;
            // flex-wrap: wrap;
            list-style: none;
            margin-left: 0;
            max-width: 550px;

            li{
                display: inline-block;
                float: left;
                border-radius: 0 12px 0 0;
                background-color: $light-gray;
                margin: 0 1rem 1rem 0;
                padding: 0 0.7rem;
                min-width: 235px;
                position: relative; 
                border-left: 3px solid;
                border-image-slice: 1;
                border-image-source: linear-gradient(180deg, $bc2 0%, $bc1 100%);                
                border-bottom: 0;
				border-right: 0;
                border-top: 0;
            	

                a{
                    display: block;
                    color: $bc4;
                    font-size: 0.9;
                    line-height: 2.5;
                }
          
            }
        }
       
    }
}