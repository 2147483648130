// =================================================
// Single Post Content
// =================================================
body.single{

    .post-content{
        padding-top: 4rem;
    }

    &-post,&-resources {
        .feat-img{
            background: #fff;
            padding: 2rem 0;
            @include breakpoint(medium up){
                padding: 4rem 0;
            }
            .post-meta-info{
                @include breakpoint(medium up){
                    padding-top: 2rem;
                }
            }
            .cat{
                color: $bc2;
                font-size: rem-calc(22);
                font-weight: 300;               
                .post-date{
                    display: inline-block !important;
                    @include breakpoint(medium up){
                        margin-left: 0.75rem;
                    }
    
                    &:before{
                        @include breakpoint(medium up){
                        content: "•"; color: $bc2;
                        display: inline-block; width: 1em;
                       // margin-left: -1em
                        }
                    }
                }
                
            }

        }

        .simple-content{
            background-color: #EDECEB;
            position: relative;
            // @include breakpoint(medium up){
            //     z-index: -1;
            //     margin-top: -20rem;
            //     margin-bottom: -12rem;
            //     padding-bottom: 10rem;
            // }

            p{
                color: $bc4;
                line-height: 1.4;
                @include breakpoint(large up){
                    max-width: 90%;
                }
            }


            &:after{
                @include breakpoint(medium up){
                    content: '';
                    display: block;
                    height: 100%;
                    position: absolute;
                    top: -35%;
                    left: 0;
                    width: 100%;
                    background-color: #EDECEB;
                    z-index: -1;
                }
            }

            .post-margin{
                margin-top: 3rem;
                @include breakpoint(medium up){
                    margin-top: -15rem;
                }

            }

        }
    }

}