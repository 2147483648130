// =================================================
// HEADER
// =================================================

.site_footer {
    background: $footer-bg;
    color: $footer-color;
	padding: 2.75rem 0;
	position: relative;
    overflow: hidden;
    z-index: 1;

	>.row{
		@include breakpoint(medium up){
			padding: 0.75rem 0;
			
		}
	}
	
	
   
		a{
			color: $footer-color;
			transition: all .15s ease-in;

			&:hover{
				color: $bc2;
			}
		}
		&__menu {
			nav{
				ul{
					padding:0;
					margin:0;
					li{
						padding:0;
						margin:0;
						list-style: none;

						a{
							color: $footer-color;
							padding:0.5rem 0;
						}
					}
				}
			}
		}

		.footer-top{
			@include breakpoint(medium up){
				border-bottom: 2px solid;
				border-image-slice: 1;
				//border-width: 2px;
				border-image-source: linear-gradient(72.37deg, $bc2 0%, $bc1 100%);		
				border-left: 0;
				border-right: 0;
				border-top: 0;		
			}

			#footer-top-right-menu{
				@include breakpoint(medium up){
					float: right;
				}

				ul{
					li{ 
						position: relative;
						&:after{
							@include breakpoint(medium up){								
								content: " ";
								position: absolute;
								border-right: 1px solid #bcbbbb;
								top: 35%;
								right: 0;
								height: 30%;
								margin-top: auto;
								margin-bottom: auto;
							}

						}

						&:last-child{
							&:after{
								display: none;
							}
						}
						

					}
				}

			}
			
		}

		.footer-bottom{
			p{
				padding: 1rem;
				font-size: 1rem;
				margin-bottom: 0;
			}
		}


  
}

