
.tabbed-content{
	.title-area{
		p{
			margin-bottom: 2rem;
		}
	}
}


.tabs-content{
  margin:0 0 3rem 0;
  padding:2rem;
}
.tabs{
  margin: 0 !important;
  background-color:#757575;
  color:$white;

  @include breakpoint(small up) {
    align-content:center;
  }
  @include breakpoint(medium up) {
    align-content:stretch;
    display:flex;
  }
  @include breakpoint(large up) {

  }




  li{
    text-align: center;

    @include breakpoint(small up) {
      width:100%;
    }
    @include breakpoint(medium up) {
      flex-grow: 1;
      width:auto;
    }
    @include breakpoint(large up) {

    }

    &.is-active a{
      background-color:rgba($bc1, 1);
      position:relative;

      &:after{
        top: 100%;
      	left: 50%;
      	border: solid transparent;
      	content: "";
      	height: 0;
      	width: 0;
      	position: absolute;
      	pointer-events: none;
      	border-color: rgba($bc1, 0);
      	border-top-color: $bc1;
      	border-width: 10px;
      	margin-left: -10px;
      }
    }

    h4{
      margin:0;
      padding:0;
      text-transform:none;
      color:$white;
    }
    a{
      color:$white;
      position:relative;

      &:hover{
        background-color:rgba($bc1, 1);

        &:after{
          top: 100%;
          left: 50%;
          border: solid transparent;
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba($bc1, 0);
          border-top-color: $bc1;
          border-width: 10px;
          margin-left: -10px;
        }
      }
    }
  }
}
