// =================================================
// CARD
// =================================================

$card_height: 346;

.card {
    position: relative;
    margin-bottom: rem-calc(30);
    transition: all 0.3s ease-in-out;
    box-shadow: none;

    .image {
        height: rem-calc(200);
        background-position: center;
        background-size: cover;
        transition: transform 1.2s ease-in-out;
    }

    .content-wrapper {
        position: relative;

        .content {
            padding: rem-calc(30) rem-calc(30) ;
            transition: all 0.15s ease-in-out;

            h3 {
                line-height: 1.2;
            }

            .pretitle {
                color: $bc1;
                font-family: $header-font-family;
            }

            p {
                margin-bottom: rem-calc(20);
                font-size: rem-calc(14);

                &.button {
                    // width: 100%;
                    margin: 0 0 rem-calc(30);
                }

                &.contact {
                    line-height: 2;
                }
            }

            .read-more {
                display: block;
                color: $bc1;
                font-family: $header-font-family;
                font-size: rem-calc(13.5);
                text-transform: uppercase;
                margin-bottom: 0;
                position: relative;
                font-weight: 500;
           

                svg{
                    position: absolute;
                    top: -45%;
                    height: 35px;
                    polygon{
                        fill: $bc1;
                    }
                }
            }

            &.bg-primary, &.bg-secondary, &.bg-tertiary, &.bg-dark-gray {
                color: $white;

                h3, a, .pretitle, .read-more {
                    color: $white;
                }

                a {
                    text-decoration: underline;
                }
            }

            &.bg-primary .button.arrow {
                color: $bc1;
            }

            &.bg-secondary .button.arrow {
                color: $bc2;
            }

            &.bg-tertiary .button.arrow {
                color: $bc3;
            }
        }
    }
   

  &.page{
    .content-wrapper{      
        .bg-grey{
            background-color: #EDECEB;
        } 

        h3{
            color: $bc1;
            font-size: rem-calc(20);
            font-weight: 500;
        }
    }
  }

    
   &.post{
       .image{
           position: relative;           

           .timestamp{
               position: absolute;
               bottom: 0;
               padding: 5px 10px;
               color: $white; 
               opacity: 0.9;
               border-radius: 0 12px 0 0;
               background: linear-gradient(180deg, $bc2 0%, $bc1 100%);
               text-align: center;   
               width: 62px;            

               time{
                   line-height: 1.5rem;
                   font-size: 0.8rem;
                   font-weight: 600;
                   span{
                       font-size: 1.85rem;
                   }
               }
           }

           
       }

       .content-wrapper{
            border-left: 3px solid $bc1;
            h3{
                color: #1D1D1B;
                font-size: rem-calc(20);
                font-weight: 500;
            }

            h5{
                color: $bc1;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 0.8rem;
            }

            .bg-grey{
                background-color: #edeceb;
            }
            
       }
      
   }
   &.grid {     
        height: rem-calc(365);       
        position: relative;
        width: 100%;        

     
        &:before{
            content: '';
            display: block;
            position: absolute;
            height: 0%;
            width: 100%;
            bottom: 0;
            transition: height 0.5s ease-out;               
            opacity: 0.8;
            background: linear-gradient(to top, #E74315 0%, #EF7D00 19.61%, rgba(87,87,86,0) 100%);
            
        }
 
    

        .img-wrap {
            position: relative;
            height: rem-calc(365);
            width: 100%;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
           // transform: scale(0.9);     

      
        }


        .slidercontent{
            position: absolute;
            bottom: 0;
            left: 0;           
            color: $white;
            padding: 1rem 2rem ;
            z-index: 2;  
            width: 100%;
           // opacity: 0.7;
            transition: all .25s ease-in-out;
            background: linear-gradient(0deg, #1D1D1B 0%, rgba(29,29,27,0) 100%);
              
            h3{
                font-size: 1.6rem;             
                font-weight: 600;
                margin-top: 0;
                margin-bottom: 0.5rem;
            }
            h5{
                font-size: 0.8rem;
                text-transform: uppercase;
                font-weight: 500;
                margin-bottom: 0;
            }

            .content{
                font-size: 0.9rem;
                font-weight: 500;
                position: absolute;
                bottom: -200px;
                transition: all .25s ease-in;
                visibility: hidden;
            }
        }
     

        &:hover{
            transform: scale(1.1);
            z-index: 3;

            &:before{
                height: 100%;     
                z-index: 2;          
            }

            .slidercontent{
                z-index: 3;
                background: none;
                .content{
                    position: relative;
                    bottom: 0;
                    visibility: visible;
                }
            }
            
        }


        
    }

    &.resources{

        &.transparent{
            background-color: transparent;            
        }

        .image{
            min-height: rem-calc(250);
            @include breakpoint(medium up){
                min-height: rem-calc(400);
            }
        }

        .content-wrapper{
            .content{
                padding: 1rem 0;
                h3{
                    color: $bc2;
                    font-size: rem-calc(26);
                    line-height: 2.1rem;

                }
                p{
                    font-size: rem-calc(15);
                }
            }
        }
    }

    &.teams{         
        .content-wrapper{
            .content{
                padding: 1rem 0;

                h3 {
                    color: $bc2;
                    font-size: rem-calc(26);
                    line-height: 2.1rem;
                    margin-bottom: 5px;
                }

                p {
                    color: $bc4;
                    margin-bottom: 0;
                    margin-bottom: 5px;
                }
            }
        }

        .image {
            height: rem-calc(400);
        }
    }
}



a:hover .card {
    transform: scale(1.025);

    // .image {
    //     transform: scale(1.05);
    // }

    .content {
        .read-more:after {
            margin-left: 0.75em;
            opacity: 1;
        }
    }

}
.row {
    .card-col{
        z-index: 2;
        position: relative;
    
        &:first-child{      
            .post{
                .image{
                    border-radius: 24px 0 0 0;
                }               
                
            }
        
        }

        &:last-child{
            .post{
                .image{
                    border-radius: 0 24px 0 0;
                }               
                
            }
        }
    }
   
} 

