// =================================================
// MIXINS
// =================================================

// Border radius
@mixin radius($size) {
    border-radius: $size + rem;
}

// icon sizer (when using, no need for you to put rem e.g. @include iconsize(1); = width&height: 1rem;
@mixin sizer($size) {
    width: $size + rem;
    height: $size + rem;
}

// Background
@mixin bg($size) {
    background-size: cover;
    background-repeat: no-repeat;
}

// Dropshadow
@mixin dropshadow($color) {
    -webkit-box-shadow: 0px 2px 30px 0px rgba($color,0.1);
    -moz-box-shadow: 0px 2px 30px 0px rgba($color,0.1);
    box-shadow: 0px 2px 30px 0px rgba($color,0.1);
}

// Placeholder
@mixin placeholder($color) {
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color;
    }
    ::-moz-placeholder { /* Firefox 19+ */
    color: $color;
    }
    :-ms-input-placeholder { /* IE 10+ */
    color: $color;
    }
    :-moz-placeholder { /* Firefox 18- */
    color: $color;
    }
}

// Text Justify
@mixin justify {
    text-align: justify;
    text-align-last: left;
}

// Fix firefox select boxes
@mixin moz-select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

// Animate
@mixin animate($time, $easing) {
    transition: all $time $easing;

    // -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
}

@mixin curv($time) {
    @include animate($time, cubic-bezier(.19,1,.22,1));
}

// Delay
@mixin animateDelay($time) {
    transition-delay: $time;
}

// Clearfix
@mixin clearfix {
    &:after {
    content: "";
    display: table;
    clear: both;
    }
}

// Vertically align some - note parent needs position: relative;
@mixin valign {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

// Centerally align something - note parent needs position: relative;
@mixin center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

// Make images have a nice effect
@mixin magic {
    filter: grayscale(30%);
    filter: contrast(-40%);
}

// Gradients
// ------

// EXAMPLE ----  @include grad($black,$black,top,bottom,10%,100%);

@mixin grad($start, $finish, $dir1, $dir2, $strength1,$strength2) {
    background: $start; /* Fallback */
    background: -moz-linear-gradient($dir1, $start $strength1, $finish $strength2); /* FF3.6-15 */
    background: -webkit-linear-gradient($dir1, $start $strength1,$finish $strength2); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to $dir2, $start $strength1,$finish $strength2); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin grad-hero {
    background: -moz-linear-gradient(top, rgba($bc1, 0) 0%, rgba($bc1, 0.1) 10%, rgba($bc1, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba($bc1, 0) 0%,rgba($bc1, 0.1) 10%,rgba($bc1, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba($bc1, 0) 0%,rgba($bc1, 0.1) 10%,rgba($bc1, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

// Goes Top to Bottom
@mixin grad-dark {
    background: $bc1; /* Fallback */
    background: -moz-linear-gradient(bottom, $bc1 0%, $bc3 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(bottom, $bc1 0%,$bc3 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, $bc1 0%,$bc3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001738', endColorstr='#000b1a',GradientType=0 ); /* IE6-9 */
}

// Goes Left to Right
@mixin grad-light {
    background: $bc2; /* Fallback */
    background: -moz-linear-gradient(left, $bc2 0%, $bc1 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, $bc2 0%,$bc1 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, $bc2 0%,$bc1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002d6e', endColorstr='#001738',GradientType=1 ); /* IE6-9 */
}

@mixin grad-text {
    @include grad-light;
    display: inline-block;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
