// =================================================
// FEATURED BLOCK - LARGE IMAGE w/ CONTENT
// =================================================

.text-image-large-block {
    // position: relative;
    // z-index: 1;


    &.toppad{
        padding-top: 6rem;
    }

    &.btmpad{
        padding-bottom: 6rem;
    }

    .media{
        @include breakpoint(medium up){
            padding: 0;
        }

        .img-wrap, img {
            min-height: 100vw;

            @include breakpoint(large up) {
                min-height: 50vw;
            }
        }
    }

    .text-block {
        padding: 3rem;
        
        @include breakpoint(medium up){
            padding: 5rem;
        }
        
        @include breakpoint(large up) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        @include breakpoint(xlarge up){
            padding: 5rem 10rem;
        }

        .content {
            h4{
                color: $bc1;
                font-size: rem-calc(26);
                font-weight: 500;
                text-transform: none;
                margin-bottom: 1rem;
            }

            p{
                color: $bc4;
                line-height: 2;
                letter-spacing: 0;
            }
        }
    }

    &.overlap {
        .img-wrap img {
            @include breakpoint(large up) {
                border-radius: 24px 0 0 0;
            }
        }

        .text-block {
            background: $white;

            .content {
                padding-bottom: 2rem;

                @include breakpoint(medium up){  
                    padding-bottom: 4rem;
                }

                h3 {
                    text-transform: capitalize;
                    background: -webkit-gradient(linear, left top, left bottom, from($bc2), to($bc1));
                    background: linear-gradient(to bottom, $bc2 0%, $bc1 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-size: rem-calc(30);
                    font-weight: 400;
                    line-height: 2;
                    margin-bottom: 1rem;
                }

                p {
                    font-size: rem-calc(15);
                    line-height: 1.5;
                }
            }

            @include breakpoint(large up) {
                top: auto;
                bottom: 0;

                &.left {
                    transform: translateX(16.667%);
                    border-radius: 24px 24px 0 24px;
                }
                
                &.right {
                    transform: translateX(-16.667%);
                    border-radius: 24px 24px 24px 0;
                }
            }
        }
    }
}  

.scroll {
    margin-bottom: 2rem;
    display: block;

    &.bottom {
        margin-bottom: 0;
        position: absolute;
        bottom: 0;
        padding: 1rem 0;

        @include breakpoint(medium up) {
            bottom: 2rem;
            margin-bottom: 0;
        }
    }

    @include breakpoint(medium up) {
        margin-bottom: 7rem;
    }
}
